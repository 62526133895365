import React, { useState } from 'react';

const QueueDisplay = ({ message, clearMessage }) => {
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      clearMessage();
    }, 300);
  };

  return (
    <div style={{ ...styles.banner, ...(isClosing ? styles.closing : {}) }}>
      <div style={styles.content}>
        <div style={styles.message}>
          {message && message.trim() ? (
            <>
              <span style={styles.highlight}>{message}</span>
            </>
          ) : null}
        </div>
        <button style={styles.closeButton} onClick={handleClose}>
          &times;
        </button>
      </div>
    </div>
  );
};

const styles = {
  banner: {
    width: '100%',
    backgroundColor: '#f9fbfc',
    color: '#333333',
    padding: '5px 15px',
    borderRadius: '4px',
    border: '1px solid #d3d9de',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
    margin: '0px 0px 10px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    transition: 'opacity 0.3s ease, transform 0.3s ease', // Smooth fade and shrink
    opacity: 1,
    transform: 'scale(1)'
  },
  closing: {
    opacity: 0,
    transform: 'scale(0.9)'
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  message: {
    fontSize: '11px',
    fontWeight: '400',
    lineHeight: '1.5',
    color: '#333333'
  },
  highlight: {
    color: '#0056b3',
    fontWeight: '600'
  },
  closeButton: {
    background: 'none',
    border: 'none',
    color: '#888',
    fontSize: '16px',
    fontWeight: 'bold',
    cursor: 'pointer',
    padding: '0 5px',
    lineHeight: '1',
    transition: 'color 0.2s ease, transform 0.3s ease',
    outline: 'none',
    marginBottom: '1px'
  }
};

export default QueueDisplay;
