import { memo } from 'react';
import './styles.scss';
import './responsive-styles.scss';

const Footer = memo(({ color, brand, brandName, routes, pathname }) => {
  return (
    <footer className="__footer__">
      <h6 className='__copy__rights__ __mb__0'>Copyright &copy; {new Date().getFullYear()}, All rights reserved by Publicis Groupe</h6>
    </footer>
  );
});

export default Footer;
