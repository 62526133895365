import React, { memo, useCallback } from 'react';
import { Button } from 'devextreme-react/button';
import ColumnInput from './columnInput';
import Column from './column';

const ColumnItem = memo(({ item, index, data, updateData }) => {
  // Function to add a new child to a specific node
  const addChild = useCallback(() => {
    const newData = [...data];
    if (!newData[index].__child) {
      newData[index].__child = [];
    }
    newData[index].__child.push({ label: '', __child: [], count: 0, uniqueKey: Math.random() });
    newData[index].count = newData[index].__child.length;
    updateData([...newData]);
  }, [data, updateData]);

  // Function to remove a child from a specific node
  const removeNode = useCallback(() => {
    const newData = [...data];
    newData.splice(index, 1);
    updateData(newData);
  }, [data, updateData]);

  // Function to toggle collapse state
  const toggleCollapse = useCallback(() => {
    const newData = [...data];
    newData[index].collapsed = !newData[index].collapsed;
    updateData(newData);
  }, [data, updateData]);

  const onInputChange = useCallback(
    (val, isTooltipText) => {
      const newData = [...data];
      newData[index][isTooltipText ? 'tooltip' : 'label'] = val;
      updateData(newData);
    },
    [data, updateData]
  );

  const columnUpdateDataHandler = useCallback(
    (updatedChild) => {
      const newData = [...data];
      newData[index].__child = updatedChild;
      newData[index].count = updatedChild.length;
      updateData(newData);
    },
    [data, updateData]
  );

  return (
    <li className="nested-item __ff__header__container__li">
      <span onClick={toggleCollapse} className="toggle-icon">
        {item.collapsed ? '➕' : '➖'}
      </span>
      <ColumnInput label={item.label} tooltip={item.tooltip} onChange={onInputChange} />
      <Button className="__ff__header__btn" text={`Add Child`} onClick={addChild} />
      <Button className="__ff__header__btn" text={`Remove`} onClick={removeNode} />
      <span className="child-count __ff__header__count __font__family__regular">
        {' '}
        (Children: {item.count})
      </span>
      {!item.collapsed && item.__child && item.__child.length > 0 && (
        <Column data={item.__child} updateData={columnUpdateDataHandler} />
      )}
    </li>
  );
});

export default ColumnItem;
