import { v4 as uuidv4 } from 'uuid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from '@mui/material/IconButton';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

/**
 * @param props
 * Function used to show user attachments in datagrid
 **/
export const renderAttachment = (props) => {
  const text = {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  };
  let attachmentValue = null;

  if (props && props.value && props.value.length) {
    attachmentValue = props.value;
  } else if (props.data.attachments && props.data.attachments.length) {
    attachmentValue = props.data.attachments;
  }

  return (
    <>
      {attachmentValue ? (
        <List
          sx={{
            width: '100%',
            maxWidth: 360,
            paddingBottom: 5,
            whiteSpace: 'normal',
            wordWrap: 'break-word'
          }}
        >
          {attachmentValue.map((v) => (
            <ListItem
              key={v.id}
              // disableGutters
              secondaryAction={
                <IconButton aria-label="download" href={v.fileUrl}>
                  <FileDownloadIcon />
                </IconButton>
              }
            >
              <ListItemText primaryTypographyProps={{ style: text }} primary={v.filePath} />
            </ListItem>
          ))}
        </List>
      ) : null}
    </>
  );
};

/**
 * @param e
 * Function used to handle collapse of the row in data-grid
 * @param collapseCurrent
 * @param callback
 **/
export const onRowExpanding = (e, collapseCurrent = true, callback = null, collapseAll = true) => {
  e?.component?.collapseAll(-1);
  // if (collapseAll) e?.component?.collapseAll(-1);
  if (collapseCurrent) e?.component?.collapseRow(e?.key);
  if (callback !== null) callback(false);
};

/**
 * @param e
 * Screen jumps away from the detail of data-grid
 **/
export const onKeyDown = (e) => (e && e.event ? e?.event?.stopPropagation() : null);

/**
 * @param e
 * @param callback
 * Function used to set state for auto width for data-grid component
 **/
export const onEditingStart = (e, callback) => callback(false);

/**
 * @param e
 * @param dataSource
 * @param setDataSource
 * @param isForDataAccess
 * @param dataSourceHasEmail
 * @param isForEmployee
 * @param isForWorkflow
 * @param dataSourceHasName
 * copy row
 **/
export function cloneIconClick(
  e,
  dataSource,
  setDataSource,
  isForDataAccess = false,
  dataSourceHasEmail = false,
  isForEmployee = false,
  isForWorkflow = false,
  dataSourceHasName = false,
  isForWorkflowHeader = false
) {
  const ds = [...dataSource];

  const cloneItemForWorkflowStep = (item) => ({
    ...item,
    id: uuidv4(),
    newRow: true,
    tableId: null
  });

  let clonedItem = { ...e.row.data, id: uuidv4(), newRow: true };

  if (dataSourceHasEmail) {
    clonedItem.email = null;
  } else if (dataSourceHasName) {
    clonedItem.name = null;
  } else if (isForDataAccess) {
    clonedItem.masterLevelId = [];
  } else if (isForEmployee) {
    clonedItem = { ...clonedItem, code: null, name: null, email: null };
  }
  if (isForWorkflowHeader) {
    clonedItem.name = null;
    clonedItem.sequence = uuidv4();
    clonedItem.workflow_steps = (e.row.data.workflow_steps || []).map(cloneItemForWorkflowStep);
    clonedItem.workflow_step = (e.row.data.workflow_step || []).map(cloneItemForWorkflowStep);
  } else if (isForWorkflow) {
    clonedItem.sequence = uuidv4();
  }

  ds.splice(0, 0, clonedItem);
  setDataSource(ds);
  e.event.preventDefault();
}

export const CustomDataGridExport = (e, dataColumns, dropDownData, tableName) => {
  const processIndividualRowConvertedToMultiple = (data) => {
    let transformedData = [];
    data.shift(); // remove first index as this is datagrid checkbox for number of rows selection
    data?.forEach((row, i) => {
      if (Array.isArray(row)) {
        row.forEach((multiSelectElementArray) => {
          const newRow = [...data];
          newRow[i] = multiSelectElementArray;
          transformedData.push(newRow);
        });
      }
    });

    if (transformedData.length === 0) {
      return [data];
    }

    return transformedData;
  };

  const processHeaderMakeColumnsIfPipeExist = (header) => {
    let newColumns = [];

    header.forEach((headerElement) => {
      if (headerElement && headerElement.includes('&')) {
        const splitHeader = headerElement.split('&');
        newColumns.push(splitHeader);
      } else {
        newColumns.push(headerElement);
      }
    });

    return newColumns.flat();
  };

  const processMakeColumnsIfPipeExist = (data, dataGridColumns) => {
    let newColumns = [];
    let newRows = [];

    data.forEach((dElement) => {
      newColumns = [];
      dElement.forEach((rElement, i) => {
        if (
          rElement &&
          !Array.isArray(rElement) &&
          dataGridColumns[i + 1]?.headingName?.includes('&')
        ) {
          const splitedColumn = rElement.split('||');
          newColumns.push(splitedColumn);
        } else {
          newColumns.push(Array.isArray(rElement) ? undefined : rElement);
        }
      });
      newRows.push(newColumns.flat());
    });

    return newRows;
  };

  // Customize each cell data for multi-select dropdowns
  const worksheetData = [];
  let hasActionColumn = false;
  const dataGridColumns = e.component.getVisibleColumns();
  let header = dataGridColumns.map((col) => {
    if (col?.name) {
      const objCol = dataColumns.filter((dElement) => dElement.dataIndex === col.name)?.shift();
      if (objCol.type === 'actions') {
        hasActionColumn = true;
      }
      let headingName = objCol.dataIndex;

      if (objCol.hasOwnProperty('exportHeading')) {
        headingName = objCol.exportHeading;
      }
      col.headingName = headingName;

      return headingName;
    }

    return undefined;
  });

  header = processHeaderMakeColumnsIfPipeExist(header);
  header.shift(); // remove first index as this is datagrid checkbox for number of rows selection
  if (hasActionColumn) header.pop();
  worksheetData.push(header);

  e.component
    .getDataSource()
    .store()
    .load()
    .then((data) => {
      data.forEach((rowData) => {
        const rowValues = dataGridColumns.map((col) => {
          const colData = rowData[col.dataField];

          if (col.hasOwnProperty('lookup')) {
            if (col?.dataType === 'number' || !Array.isArray(colData)) {
              return (
                dropDownData[col.dataField]?.filter((dVal) => dVal.id === colData)?.[0]?.label ??
                undefined
              );
            } else {
              const cloneColData = [];
              colData?.forEach((val, i) => {
                cloneColData[i] = dropDownData[col.dataField].filter(
                  (dVal) => dVal.id === val
                )?.[0]?.label;
              });

              return cloneColData;
            }
          }

          return colData;
        });

        // process each row which has array, convert it on multiple rows
        const individualRowConvertedToMultiple = processIndividualRowConvertedToMultiple(rowValues);

        const makeColumnsIfPipeExist = processMakeColumnsIfPipeExist(
          individualRowConvertedToMultiple,
          dataGridColumns
        );
        makeColumnsIfPipeExist?.forEach((eachRow) =>
          worksheetData.push(
            eachRow?.map((rElement) =>
              typeof rElement !== 'boolean' && typeof rElement !== 'number'
                ? rElement?.trim()
                : rElement
            )
          )
        );
      });

      // Create a worksheet and fill it with the transformed data
      const ws = XLSX.utils.aoa_to_sheet(worksheetData);

      // Create a workbook and append the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Data Table');

      // Write the buffer and save the file
      const buffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

      const blob = new Blob([buffer], { type: 'application/octet-stream' });
      saveAs(blob, `${e.fileName}-${tableName}.xlsx`);
    });

  e.cancel = true; // Prevent default export
};
