import React, { memo } from 'react';
import { Card, Col } from 'antd';
import FileUploader from 'devextreme-react/file-uploader';

const UploadTemplateCard = memo(({ handleFileChange, handleFileUpload }) => (
  <div
    style={{
      width: '500px'
    }}
  >
    <div style={{ paddingLeft: '50px' }}>
      <FileUploader
        multiple={false}
        accept=".xlsx"
        uploadMode="instantly"
        onValueChanged={handleFileChange}
        uploadFile={handleFileUpload}
      />
    </div>
  </div>
));

export default UploadTemplateCard;
