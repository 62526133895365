import request from "../services/Http"
import { useContext, useState } from "react";
import { Context } from "../context/store/Store";
import { SET_IS_LOADING, SET_STRUCTURE_MANAGEMENT, SET_STRUCTURE_MANAGEMENT_TABS } from "../context/store/Constants";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    setColumnsForDataTable,
    isJSONValid,
    addKeyAndIndexFieldToGenericArrayOfObjects,
    getPageProperties, getTableColumns,
} from "../services/Helpers";
import { v4 as uuidv4 } from 'uuid';
import { useIsLoading } from "./useIsLoading";

export default function useStructureManagement(routeKey) {

    const [{ structureManagement, pagesAndPermissions }, dispatch] = useContext(Context)
    const { isLoading, setIsLoading } = useIsLoading()
    const [recordsForSequence, setRecordsForSequence] = useState({});

    async function getStructureManagementTabs() {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })
        try {
            const res = await request.get('org-structure')
            let rows = res.data.data ?? []
            dispatch({ type: SET_STRUCTURE_MANAGEMENT_TABS, payload: rows });

        }
        catch (e) { }
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    async function getStructureManagement(data_by) {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })
        try {
            const res = await request.get(data_by)
            const properties = getPageProperties(pagesAndPermissions, routeKey)
            const table = getTableColumns(properties.page.table, data_by)
            let columns = table.columns
            let rows = isJSONValid(res.data.data) ?? {}
            let newRowObject = isJSONValid(table.row.defaultObj) ?? {}
            let dropdownValues = isJSONValid(res.data.dropdownValues) ?? {}
            let dependencyCount = isJSONValid(res.data.dependencyCount) ?? {}
            let uploadTemplateLink = res.data.uploadTemplate ?? null
            let orgStructureLink = res.data.orgStructureLink ?? null
            let tableName = res.data.tableName ?? null

            if (data_by === 'entity') {
                rows.forEach((row) => {
                    row.count = 0
                    dependencyCount.forEach((obj) => {
                        if (row.id === obj.id) {
                            row.count = obj.count
                        }
                    })
                })
            }

            if (data_by === 'vertical') {
                rows.forEach((row) => {
                    row.count = 0
                    dependencyCount.forEach((obj) => {
                        if (row.id === obj.id) {
                            row.count = obj.count
                        }
                    })
                })
            }

            if (structureManagement && structureManagement.hasOwnProperty('apiDelete') && structureManagement.apiDelete) {
                rows = [...structureManagement.rows, ...rows]
            }

            const dataTableData = { columns: setColumnsForDataTable(columns, dropdownValues), rows: addKeyAndIndexFieldToGenericArrayOfObjects(rows), newRowObject: newRowObject, dropdownValues: dropdownValues, apiDelete: false, uploadTemplateLink, orgStructureLink, tableName }
            dispatch({
                type: SET_STRUCTURE_MANAGEMENT,
                payload: dataTableData
            });

            setIsLoading(false)

        }
        catch (e) { }
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    function getNewRowObject(length) {

        const newRowObj = structureManagement.newRowObj

        newRowObj.sequence = length
        newRowObj.index = uuidv4()
        newRowObj.key = uuidv4()

        return newRowObj
    }

    async function updateOrCreateStructureManagement(data, val) {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })
        try {
            const res = await request.post(val, { orgstruct: data })
            toast.success(res.data.data)
            if (res)
                await getStructureManagement(val)
        }
        catch (e) { }
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    /**
     * @param data
     * @param val
     * function used to manage delete api call for org structure
     **/
    async function deleteStructureManagement(data, val) {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })
        try {
            const res = await request.delete(val, {
                data: {
                    orgstruct: data
                }
            })
            if (res) await getStructureManagement(val)
            toast.success(res.data.data);
        }
        catch (e) { }
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    async function getAllSequenceForPDtBl() {
        setIsLoading(true);
        try {
            const res = await request.get(`practice-detail-type-budget-lines/sequence`);
            if (res) {
                let rows =
                    res.data.data?.sort((a, b) => {
                        if (a.sequence === null) return 1;
                        if (b.sequence === null) return -1;
                        return a.sequence - b.sequence;
                    }) ?? [];
                const properties = getPageProperties(pagesAndPermissions, 'structure-management');
                const table = getTableColumns(properties.page.table, 'pdtbl_sequence');
                let columns = table.columns;
                const dataTableData = { columns, rows };
                setRecordsForSequence(dataTableData);
            }
        } catch (e) {
        } finally {
            setIsLoading(false);
        }
    }

    async function updateTransactionSequence(data) {
        setIsLoading(true);
        try {
            const res = await request.post('practice-detail-type-budget-lines/update-transaction-sequence', {
                data
            });
            if (res) {
                toast.success(res.data.data);
                await getAllSequenceForPDtBl();
            }
        } catch (e) {
        } finally {
            setIsLoading(false);
        }
    }

    return { getStructureManagementTabs, getStructureManagement, getNewRowObject, updateOrCreateStructureManagement, deleteStructureManagement, getAllSequenceForPDtBl, updateTransactionSequence, isLoading, recordsForSequence }
}