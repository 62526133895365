import React, { useState, useEffect, useMemo, useCallback, useRef, memo } from 'react';
import { HtmlEditor, Toolbar, Item } from 'devextreme-react/html-editor';
import { Button } from 'devextreme-react/button';
import { Form, GroupItem, SimpleItem, Label, RequiredRule } from 'devextreme-react/form';
import { ValidationGroup } from 'devextreme-react/validation-group';
import './EmployeeResignation.css';
import CustomFileUploader from '../../components/CustomFileUploader';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { isObjectEmpty } from 'utils/services/Helpers';
import { ProgressSteps } from '../../components/CustomDataGridComponents';
import Swal from 'sweetalert2';
import useApprovals from 'utils/hooks/useApprovals';
import { v4 as uuidv4 } from 'uuid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from '@mui/material/IconButton';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

const EmployeeResignation = memo(
  ({
    masterEmployeeDetail,
    dropdownData,
    onSubmit,
    onCancel,
    resignationData = {},
    apiCallBack
  }) => {
    const navigate = useNavigate();
    const formRef = useRef(null);

    const [formData, setFormData] = useState({
      resignationDate: new Date(),
      lastWorkingDay: null,
      masterExitReasonId: null,
      comments: '',
      resignationNote: '',
      willRejoinId: null,
      attachment: [],
      ...resignationData
    });

    const { approveOrReject } = useApprovals();

    const isDraft = resignationData?.isDraft ?? true;

    const noticePeriod = useMemo(
      () => masterEmployeeDetail?.noticePeriod || 0,
      [masterEmployeeDetail]
    );

    useEffect(() => {
      if (isObjectEmpty(resignationData) || resignationData?.isDraft === true) {
        const lastDay = new Date(formData.resignationDate);
        lastDay.setDate(lastDay.getDate() + noticePeriod);
        setFormData((prevData) => ({ ...prevData, lastWorkingDay: lastDay }));
      }
    }, [formData.resignationDate, noticePeriod]);

    useEffect(() => {
      if (!isObjectEmpty(resignationData)) {
        setFormData((prevData) => ({ ...prevData, ...resignationData }));
      }
    }, [resignationData]);

    const handleFieldChange = useCallback((field, value) => {
      setFormData((prevData) => ({
        ...prevData,
        [field]: value
      }));
    }, []);

    const handleSubmit = useCallback(
      (isDraft) => {
        const validationResult = formRef.current.instance.validate();
        if (formData.hasOwnProperty('attachment') && formData.attachment.length) {
          formData['hasFile'] = true;
        }
        if (isObjectEmpty(resignationData)) {
          formData.newRow = true;
          formData.id = uuidv4();
        }
        if (validationResult.isValid || isDraft) {
          const formDataWithDraft = {
            ...formData,
            ...masterEmployeeDetail,
            isDraft
          };
          onSubmit(formDataWithDraft);
        } else {
          toast.error('Please fill all mandatory fields.');
        }
      },
      [formData, onSubmit]
    );

    const showCancelAlert = useCallback(async () => {
      const result = await Swal.fire({
        title: 'Cancel Exit Request',
        input: formData?.status ? 'textarea' : null, // Show textarea only if formData?.status is true
        inputPlaceholder: 'Enter your comments...',
        inputAttributes: {
          'aria-label': 'Enter your comments here'
        },
        showCancelButton: true,
        confirmButtonText: 'Yes, proceed',
        cancelButtonText: 'Back',
        preConfirm: (comments) => {
          if (formData?.status && !comments) {
            Swal.showValidationMessage('Comments are required to proceed.');
          }
          return comments; // Resolve with comments if present
        },
        customClass: {
          container: '__swal__continer__ __font__family__regular',
          confirmButton: '__default__button__layout __btn__green __primary__color __unset__border',
          cancelButton: '__default__button__layout __primary__color __unset__border'
        }
      });

      if (result.isConfirmed) {
        try {
          await onCancel(formData, result?.value); // Call API on confirm
        } catch (error) {
          toast.error('Failed to cancel exit request.');
        }
      }
    }, [formData, navigate]);

    /**
     * Handle Approve Request
     **/
    const handleApprove = async () => {
      await approveOrReject(true, [formData], 'transaction_exit', 10, null, null);
    };

    /**
     * Handle Reject Request
     **/
    const handleReject = async () => {
      await approveOrReject(false, [formData], 'transaction_exit', 10, null, null);
    };

    const renderButtons = useCallback(() => {
      const commonButtons = [
        <Button
          key="back"
          text="Back"
          type="default"
          onClick={() => navigate('/views/manage-exits')}
          className="submit-button __font__family__regular __default__button__layout"
        />
      ];

      if (isObjectEmpty(resignationData) || resignationData?.isDraft === true) {
        return [
          <Button
            disabled={!masterEmployeeDetail}
            key="save"
            text="Save"
            type="info"
            onClick={() => handleSubmit(true)}
            className="submit-button __font__family__regular __default__button__layout __unset__border"
          />,
          <Button
            disabled={!masterEmployeeDetail}
            key="submit"
            text="Submit"
            type="success"
            onClick={() => handleSubmit(false)}
            className="submit-button __font__family__regular __default__button__layout __btn__green"
          />,
          ...commonButtons
        ];
      } else if (resignationData?.needApproval) {
        return [
          <Button
            key="approve"
            text="Approve"
            type="success"
            onClick={handleApprove}
            className="submit-button __font__family__regular __default__button__layout __btn__green"
          />,
          <Button
            key="reject"
            text="Reject"
            type="danger"
            onClick={handleReject}
            className="submit-button __font__family__regular __default__button__layout __btn__red"
          />,
          ...(resignationData?.isInitiator
            ? [
                <Button
                  key="cancel"
                  text="Cancel"
                  type="danger"
                  onClick={showCancelAlert}
                  className="submit-button __font__family__regular __default__button__layout __btn__red"
                />
              ]
            : []),
          ...commonButtons
        ];
      } else if (
        resignationData?.isInitiator &&
        (resignationData?.status === null ||
          (resignationData?.status === true && !resignationData?.cancelled))
      ) {
        return [
          <Button
            key="cancel"
            text="Cancel"
            type="danger"
            onClick={showCancelAlert}
            className="submit-button __font__family__regular __default__button__layout __btn__red"
          />,
          ...commonButtons
        ];
      } else if (!resignationData?.isInitiator && resignationData?.canEditAfterApproval) {
        return [
          <Button
            key="submit"
            text="Submit"
            type="success"
            onClick={() => handleSubmit(false)}
            className="submit-button __font__family__regular __default__button__layout __btn__green"
          />,
          ...commonButtons
        ];
      } else {
        return commonButtons;
      }
    }, [resignationData, navigate, handleSubmit, showCancelAlert]);

    const disabled = !isDraft; // Disable all fields if not a draft

    return (
      <div className="employee-resignation-container">
        {resignationData?.steps?.length > 0 && (
          <div className="__progress__steps__">
            <ProgressSteps steps={resignationData?.steps} />
          </div>
        )}
        <div style={{ padding: '16px', whiteSpace: 'normal', flex: '1' }}>
          <h2 className="section-header">Employee Details</h2>
          <Form formData={masterEmployeeDetail} readOnly>
            <GroupItem colCount={2}>
              {[
                { dataIndex: 'llid', label: 'LLID' },
                { dataIndex: 'name', label: 'Name' },
                { dataIndex: 'manager', label: 'Line Manager' },
                { dataIndex: 'noticePeriod', label: 'Notice Period' }
              ].map((field) => (
                <SimpleItem key={field.dataIndex} dataField={field.dataIndex}>
                  <Label text={field.label} />
                </SimpleItem>
              ))}
            </GroupItem>
          </Form>

          <h2 className="section-header __mt__15">Resignation Form</h2>
          <ValidationGroup>
            <Form ref={formRef}>
              <GroupItem colCount={2}>
                <SimpleItem
                  editorType="dxDateBox"
                  editorOptions={{
                    value: formData.resignationDate,
                    onValueChanged: (e) => handleFieldChange('resignationDate', e.value),
                    min: new Date(),
                    openOnFieldClick: true,
                    pickerType: 'calendar',
                    displayFormat: 'dd-MM-yyyy',
                    disabled
                  }}
                >
                  <Label text="Resignation Date" />
                  <RequiredRule message="Resignation Date is required" />
                </SimpleItem>

                <SimpleItem
                  editorType="dxDateBox"
                  editorOptions={{
                    value: formData.lastWorkingDay,
                    onValueChanged: (e) => handleFieldChange('lastWorkingDay', e.value),
                    openOnFieldClick: true,
                    pickerType: 'calendar',
                    readOnly: !resignationData?.canEditAfterApproval,
                    displayFormat: 'dd-MM-yyyy',
                    disabled: !resignationData?.canEditAfterApproval
                  }}
                >
                  <Label text="Last Working Day" />
                </SimpleItem>

                <SimpleItem
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: dropdownData?.masterExitReasonId,
                    value: formData.masterExitReasonId,
                    onValueChanged: (e) => handleFieldChange('masterExitReasonId', e.value),
                    placeholder: 'Select a reason',
                    valueExpr: 'id',
                    displayExpr: 'label',
                    showClearButton: true,
                    searchEnabled: true,
                    disabled
                  }}
                >
                  <Label text="Reason for Resignation" />
                  <RequiredRule message="Reason for Resignation is required" />
                </SimpleItem>

                <SimpleItem
                  visible={formData.masterExitReasonId === 16}
                  editorType="dxTextBox"
                  editorOptions={{
                    value: formData.comments,
                    onValueChanged: (e) => handleFieldChange('comments', e.value),
                    placeholder: 'Enter your comments',
                    disabled
                  }}
                  isRequired={formData.masterExitReasonId === 16}
                >
                  <Label text="Comments" />
                  {formData.masterExitReasonId === 16 && (
                    <RequiredRule message="Comments are required if 'Reason' is 'Other'" />
                  )}
                </SimpleItem>

                <SimpleItem>
                  <Label text="Attachments" />
                  <CustomFileUploader
                    filesValue={formData.attachment}
                    onValueChanged={(e) => handleFieldChange('attachment', e.value)}
                    selectButtonText="Select a file"
                    disabled={disabled}
                  />
                  {formData?.attachments && formData?.attachments?.length ? (
                    <List
                      sx={{ width: 'fit-content', whiteSpace: 'normal', wordWrap: 'break-word' }}
                    >
                      {formData?.attachments?.map((v) =>
                        !v.hasOwnProperty('isDeleted') ? (
                          <ListItem key={v.id}>
                            <ListItemText
                              primaryTypographyProps={{ style: { fontSize: '14px' } }}
                              primary={v?.filePath}
                            />
                            <IconButton aria-label="download" href={v?.fileUrl}>
                              <FileDownloadIcon />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              onClick={() => {
                                const att = formData?.attachments.map((file) =>
                                  file.filePath === v.filePath ? { ...file, isDeleted: true } : file
                                );
                                setFormData((prevData) => ({
                                  ...prevData,
                                  attachments: att
                                }));
                              }}
                            >
                              <DeleteOutlinedIcon />
                            </IconButton>
                          </ListItem>
                        ) : null
                      )}
                    </List>
                  ) : null}
                </SimpleItem>

                {/*<SimpleItem editorType="dxSelectBox" editorOptions={{*/}
                {/*  items: dropdownData?.willRejoinId,*/}
                {/*  value: formData.willRejoinId,*/}
                {/*  onValueChanged: (e) => handleFieldChange('willRejoinId', e.value),*/}
                {/*  placeholder: 'Select willingness',*/}
                {/*  valueExpr: 'id',*/}
                {/*  displayExpr: 'label',*/}
                {/*  showClearButton: true,*/}
                {/*  searchEnabled: true,*/}
                {/*  disabled,*/}
                {/*}}>*/}
                {/*  <Label text="Willingness to Rejoin" />*/}
                {/*  <RequiredRule message="Willingness to Rejoin is required" />*/}
                {/*</SimpleItem>*/}
              </GroupItem>

              {/*<GroupItem colCount={1} className="custom-item-wrapper">*/}
              {/*  <SimpleItem>*/}
              {/*    <Label text="Resignation Note" />*/}
              {/*    <HtmlEditor*/}
              {/*      value={formData.resignationNote}*/}
              {/*      onValueChanged={(e) => handleFieldChange('resignationNote', e.value)}*/}
              {/*      height={200}*/}
              {/*      disabled={disabled}*/}
              {/*    >*/}
              {/*      <Toolbar>*/}
              {/*        <Item name="bold" />*/}
              {/*        <Item name="italic" />*/}
              {/*        <Item name="underline" />*/}
              {/*      </Toolbar>*/}
              {/*    </HtmlEditor>*/}
              {/*  </SimpleItem>*/}
              {/*</GroupItem>*/}

              {/*<GroupItem colCount={1} className="custom-item-wrapper">*/}
              {/*  <SimpleItem>*/}
              {/*    <Label text="Attachments" />*/}
              {/*    <CustomFileUploader*/}
              {/*      filesValue={formData.attachment}*/}
              {/*      onValueChanged={(e) => handleFieldChange('attachment', e.value)}*/}
              {/*      selectButtonText="Select a file"*/}
              {/*      disabled={disabled}*/}
              {/*    />*/}
              {/*    {formData?.attachments && formData?.attachments?.length*/}
              {/*      ? <List sx={{ width: 'fit-content', whiteSpace: 'normal', wordWrap: 'break-word' }}>*/}
              {/*        {formData?.attachments?.map((v) => (*/}
              {/*          !v.hasOwnProperty('isDeleted') ? (*/}
              {/*            <ListItem key={v.id}>*/}
              {/*              <ListItemText primaryTypographyProps={{ style: { fontSize: "14px" } }} primary={v?.filePath} />*/}
              {/*              <IconButton aria-label="download" href={v?.fileUrl}>*/}
              {/*                <FileDownloadIcon />*/}
              {/*              </IconButton>*/}
              {/*              <IconButton aria-label="delete" onClick={() => {*/}
              {/*                console.log('formData1', formData)*/}
              {/*                const att = formData?.attachments.map(file => (file.filePath === v.filePath) ? { ...file, isDeleted: true } : file)*/}
              {/*                console.log('att', att)*/}
              {/*                setFormData((prevData) => ({*/}
              {/*                  ...prevData,*/}
              {/*                  attachments: att,*/}
              {/*                }));*/}
              {/*                console.log('formData2', formData)*/}
              {/*              }}>*/}
              {/*                <DeleteOutlinedIcon />*/}
              {/*              </IconButton>*/}
              {/*            </ListItem>*/}
              {/*          ) : null*/}
              {/*        ))}*/}
              {/*      </List>*/}
              {/*      : null}*/}
              {/*  </SimpleItem>*/}
              {/*</GroupItem>*/}
            </Form>

            <div className="__form__container__">
              <div className="__form__action__section__">{renderButtons()}</div>
            </div>
          </ValidationGroup>
        </div>
      </div>
    );
  }
);

export default EmployeeResignation;
