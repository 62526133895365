import { useContext } from 'react';
import { Context } from '../context/store/Store';
import request from '../services/Http';
import {
  SET_IS_LOADING,
  SET_NOTIFICATIONS,
  SET_APP_NOTIFICATIONS
} from '../context/store/Constants';
import { useIsMounted } from './useIsMounted';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

export default function useNotification() {
  const [{ appNotifications }, dispatch] = useContext(Context);
  const isMounted = useIsMounted();
  const navigate = useNavigate();

  async function getNotifications() {
    const res = await request.get('notification');
    if (res && isMounted()) {
      console.log('@uz res.data.data:: ', res.data.data);
      dispatch({
        type: SET_APP_NOTIFICATIONS,
        payload: res?.data?.data ?? []
      });
    }
  }

  async function markNotificationsAsRead(id = null, redirectLink = null) {
    const notificationId = typeof id === 'object' ? null : id;
    const res = await request.put('notification', { id: notificationId });

    if (res) {
      await getNotifications();
      if (redirectLink) {
        dispatch({ type: SET_IS_LOADING, payload: true });
        const uniqueID = uuidv4();
        const luxuryURL = `${redirectLink}?notificationId=${uniqueID}`;
        navigate(luxuryURL);
        setTimeout(() => {
          navigate(redirectLink, { replace: true });
          dispatch({ type: SET_IS_LOADING, payload: false });
        }, 100);
      }
    }
  }

  return { getNotifications, markNotificationsAsRead, appNotifications };
}
