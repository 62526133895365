import React, { useCallback } from 'react';
import SelectBox from 'devextreme-react/select-box';
import { TextBox } from 'devextreme-react/text-box';

const LevelsField = ({ levels, setLevels }) => {
  const handleOnValueChangedSelectBox = useCallback(
    (e, index) => {
      const newLevels = [...levels];
      newLevels[index].key = e.value;
      setLevels(newLevels);
    },
    [levels, setLevels]
  );

  const handleOnValueChangedTextBox = useCallback(
    (e, index, objKey) => {
      const newLevels = [...levels];
      newLevels[index][objKey] = e.event.target.value;
      setLevels(newLevels);
    },
    [levels, setLevels]
  );

  const renderFieldBox = useCallback(() => {
    return levels.map(({ key, text, tooltip }, i) => (
      <div key={`fieldbox-level-${i}`} className="__ff__header__container__levels">
        <SelectBox
          key={`selectbox-level-${i}`}
          width={200}
          className="display-inline-block __ff__header__dropdown margin-right-10"
          showClearButton={true}
          placeholder={`level ${i + 1}`}
          onValueChanged={(e) => handleOnValueChangedSelectBox(e, i)}
          value={key}
          valueExpr="id"
          displayExpr="label"
          dataSource={levels}
        />
        <TextBox
          key={`textbox-level-${i}`}
          className="display-inline-block __ff__header__textbox margin-right-10"
          width={200}
          value={text}
          label={`level ${i + 1}`}
          onKeyUp={(e) => handleOnValueChangedTextBox(e, i, 'text')}
        />
        {text && (
          <TextBox
            key={`textbox-level-tooltip-${i}`}
            className="display-inline-block __ff__header__textbox"
            width={200}
            value={tooltip}
            label={`Tooltip ${i + 1}`}
            onKeyUp={(e) => handleOnValueChangedTextBox(e, i, 'tooltip')}
          />
        )}
      </div>
    ));
  }, [levels, handleOnValueChangedSelectBox, handleOnValueChangedTextBox]);

  return renderFieldBox();
};

export default LevelsField;
