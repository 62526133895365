import React, { useCallback, useEffect } from 'react';
import Loadable from 'react-loadable';
import { registerAllModules } from 'handsontable/registry';
import Skelton from '../../../components/Skelton/defaultSkelton';
const MITReportingView = Loadable({
  loader: () => import('./MITReporting'),
  loading: () => <Skelton />
});
const RevenueReportingView = Loadable({
  loader: () => import('./RevenueReporting'),
  loading: () => <Skelton />
});
import 'handsontable/dist/handsontable.min.css';
import '../components/styles.scss';
import { SelectBox } from 'devextreme-react/select-box';
import useRFReporting from '../../../utils/hooks/useRFReporting';

registerAllModules();

const RFReport = ({ routeKey, pageName }) => {
  const RfReporting = useRFReporting(routeKey)
  const {dropDownData, setPageSelectBoxes, value, handlePageDropDown} = RfReporting

  useEffect(() => {
    setPageSelectBoxes();
  }, [])

  const MITReport = useCallback(() => {
    return <MITReportingView {...RfReporting}/>
  }, [RfReporting])

  const RevenueReport = useCallback(() => {
    return <RevenueReportingView {...RfReporting}/>
  }, [RfReporting])

  const itemRender = useCallback((data) => (
    <div className="__financial__forecast__selectbox__tagbox__">{data.label}</div>
  ), [])

  return (
    <div className="__body__section __base__layout__container __financial__forcast__">
      <h3> {value?.toUpperCase() || ""} Reporting</h3>
      <div className="action__container">
        <div className="elements__row">
          <SelectBox
            width={300}
            height={30}
            placeholder={'Please select option... '}
            dataSource={dropDownData.pageList}
            value={value || null}
            searchEnabled={true}
            showClearButton={true}
            valueExpr="value"
            displayExpr="label"
            onValueChanged={(e) => handlePageDropDown(e.value)}
            className={`__select__box__ __mt__15 __font__size__11`}
            itemRender={itemRender}
          />
        </div>
      </div>
        <div className="__financial__forcast__section__ __font__family__regular __mt__0">
          <div className="__financial__forecast__table__">
            {
              value && value === "mit" ? MITReport() : ""
            }
            {
              value && value === "revenue" ? RevenueReport() : ""
            }
          </div>
        </div>
      </div>
      );
};

export default RFReport;
