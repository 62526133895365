import React, { useContext, useEffect, useMemo, useRef, useState, Fragment, memo } from 'react';
import DataGrid, {
  AsyncRule,
  Button,
  Column,
  Editing,
  Export,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  RequiredRule,
  Scrolling,
  SearchPanel,
  Selection
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.light.css';
import {
  CustomDateBox,
  CustomFileInput,
  CustomNumberBox,
  CustomTextArea
} from '../../../components/CustomDataGridComponents';
import {
  addRow,
  handleDeleteRequest,
  isObjectEmpty,
  hasDuplicates,
  showDialogBox,
  DEButton,
  createSanitizeAsyncRule
} from '../../../utils/services/Helpers';
import MDAlert from '../../../components/MDAlert';
import MDTypography from '../../../components/MDTypography';
import Divider from '@mui/material/Divider';
import MDBox from '../../../components/MDBox';
import Grid from '@mui/material/Grid';
import {
  cloneIconClick,
  CustomDataGridExport,
  onEditingStart,
  onRowExpanding
} from '../../../utils/services/DatagridHelpers';
import { Context } from '../../../utils/context/store/Store';
import DeleteIcon from '@mui/icons-material/Delete';
import { SET_STRUCTURE_MANAGEMENT } from '../../../utils/context/store/Constants';
import { toast } from 'react-toastify';
import DetectNavigationBlocker from 'components/navigationdetector/DetectNavigationBlocker';
import { TagBox } from 'devextreme-react/tag-box';
import CustomSkelton from 'components/Skelton/CustomSkelton';
import BulkUploaderModal from '../../../components/Modal/BulkUploader/BulkUploaderModal';
import Loadable from 'react-loadable';
import Skelton from 'components/Skelton/defaultSkelton';
const _ = require('lodash');
const SequenceModal = Loadable({
  loader: () => import('../SequenceModal'),
  loading: () => <Skelton />
});

const StructureManagementDataGrid = memo(
  ({
    data,
    rows,
    columns,
    dropDownData,
    isLoading,
    showButton = true,
    routeKey,
    permissions,
    allowAdding = true,
    allowUpdating = true,
    allowSelection = false,
    allowDeleting = true,
    postData,
    valueToFetch,
    handleDelete,
    allowDeletingFromApi = true,
    bulkUploadApi,
    uploadTemplateLink,
    orgStructureLink,
    apiCallback,
    tableName,
    allowBulkUploading = false
  }) => {
    const [dataSource, setDataSource] = useState([]);
    const [dataColumns, setDataColumns] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [hasDataChanged, setHasDataChanged] = useState(false);
    const [autoWidth, setAutoWidth] = useState(true);
    const dataGridRef = useRef();
    const [addEditMode, setAddEditMode] = useState(false);
    const [hasValue, setHasValue] = useState(0);
    const [bulkUploadModalVisible, setBulkUploadModalVisible] = useState(false);
    const [isSequenceModalVisible, setSequenceModalVisible] = useState(false);
    const [{ structureManagement }, dispatch] = useContext(Context);
    const sanitizeAsyncRule = createSanitizeAsyncRule(
      'Invalid characters detected. Please remove any special characters.'
    );

    useEffect(() => {
      setSelectedRowKeys([]);

      // cleanup on unmount
      return () => {
        setDataSource([]);
        setDataColumns([]);
      };
    }, []);

    useEffect(() => {
      if (valueToFetch) {
        setSelectedRowKeys([]);
        setHasDataChanged(false);
      }
    }, [valueToFetch]);

    useEffect(() => {
      setDataSource(rows);
    }, [rows]);

    useEffect(() => {
      setDataColumns(columns);
    }, [columns]);

    /**
     * @param array
     * function use to check duplicate values of the same combination in specific categories of org structure
     **/
    const hasDuplicateObjects = (array) => {
      if (valueToFetch === 'entity-country-vertical-function') {
        return array
          .map(function (value) {
            if (typeof value.orgEntityCountryId === 'object') {
              return value.orgEntityCountryId.id;
            } else {
              return value.orgEntityCountryId;
            }
            // return value.entity_masterCountryId.toString() + value.vertical_functionId.toString()
          })
          .some(function (value, index, array) {
            return array.indexOf(value) !== array.lastIndexOf(value);
          });
      } else if (valueToFetch === 'entity-country-hfm') {
        return array
          .map(function (value) {
            if (value.hasOwnProperty('masterOrgHfmId')) {
              return value.masterOrgHfmId;
            }
          })
          .some(function (value, index, array) {
            if (value != undefined) {
              return array.indexOf(value) !== array.lastIndexOf(value);
            }
          });
      }
    };

    /**
     * @param e
     * function use perform operation when data-grid editor is not prepared e.g enable/disable field etc
     **/
    async function onEditorPreparing(e) {
      if (
        (valueToFetch !== 'org-entity-clients' &&
          valueToFetch !== 'entity-type-budget' &&
          e &&
          e.dataField === 'masterOrgEntityId') ||
        e.dataField === 'masterCountryId' ||
        e.dataField === 'masterOrgVerticalId'
      ) {
        let gridInstance = dataGridRef.current.instance;
        let editRowKey = gridInstance.option('editing.editRowKey');
        let index = gridInstance.getRowIndexByKey(editRowKey);
        const masterOrgDivisionId = gridInstance.cellValue(index, 'masterOrgDivisionId');
        if (!masterOrgDivisionId) e.editorOptions.disabled = true;
        else e.editorOptions.disabled = false;
      }
    }

    /**
     * @param e
     * function executed on datagrid cell click
     **/
    function handleCellClick(e) {}

    /**
     * @param selectedRowKeys
     * @param selectedRowsData
     * used to get selected rows detail of data-grid
     **/
    function onSelectionChanged({ selectedRowKeys, selectedRowsData }) {
      const final = selectedRowsData.filter((val) => val.count > 0);
      setHasValue(final.length);
      setSelectedRowKeys(selectedRowsData);
    }

    /**
     * get selected rows
     **/
    const hasSelected = selectedRowKeys.length > 0;

    /**
     * @param col
     * @param dropDownData
     * function use to handle rendering of fields
     **/
    function renderField(col, dropDownData) {
      if (col.type === 'select') {
        if (!col.hasOwnProperty('filtrationKey')) {
          return (
            <Column
              key={col.dataIndex}
              editorOptions={{ dropDownOptions: { width: 'auto' } }}
              allowEditing={col.editable}
              visible={col.is_visible}
              allowSearch={col.is_searchable}
              allowSorting={col.is_sortable}
              dataField={col.dataIndex}
              caption={col.title}
              setCellValue={function (rowData, value) {
                if (col.dataIndex === 'masterOrgDivisionId') {
                  rowData['masterOrgEntityId'] = null;
                  rowData['masterCountryId'] = null;
                  rowData['masterOrgVerticalId'] = null;
                }

                if (col.dataIndex === 'masterOrgEntityId') {
                  let gridInstance = dataGridRef.current.instance;
                  let editRowKey = gridInstance.option('editing.editRowKey');
                  let index = gridInstance.getRowIndexByKey(editRowKey);
                  const meId = gridInstance.cellValue(index, 'masterOrgEntityId');
                  if (meId) {
                    if (meId !== value) {
                      rowData['entityChanged'] = true;
                      rowData['oldEntityId'] = meId;
                    }
                  }
                }

                if (col.hasOwnProperty('bindedTo')) rowData[col.bindedTo] = null;
                this.defaultSetCellValue(rowData, value);
              }}
            >
              {col.required ? <RequiredRule /> : null}
              <Lookup
                allowClearing
                dataSource={
                  dropDownData && dropDownData.hasOwnProperty(col.dataIndex)
                    ? dropDownData[col.dataIndex]
                    : []
                }
                displayExpr="label"
                valueExpr="id"
              />
            </Column>
          );
        } else if (col.hasOwnProperty('filtrationKey')) {
          if (col.dataIndex === 'masterOrgEntityId') {
            return (
              <Column
                key={col.dataIndex}
                editorOptions={{ dropDownOptions: { width: 'auto' } }}
                allowEditing={col.editable}
                visible={col.is_visible}
                allowSearch={col.is_searchable}
                allowSorting={col.is_sortable}
                dataField={col.dataIndex}
                caption={col.title}
                setCellValue={function (rowData, value) {
                  this.defaultSetCellValue(rowData, value);
                  if (col.dataIndex === 'masterOrgEntityId') {
                    rowData['masterCountryId'] = null;
                    rowData['masterOrgVerticalId'] = null;
                  }
                  if (col.hasOwnProperty('bindedTo')) {
                    rowData[col.bindedTo] = null;
                  }
                }}
              >
                <Lookup
                  allowClearing
                  dataSource={(options) => {
                    return getEntitiesByDivision(options);
                  }}
                  displayExpr="label"
                  valueExpr="id"
                />
                {col.required ? <RequiredRule /> : null}
              </Column>
            );
          } else if (col.dataIndex === 'masterCountryId') {
            return (
              <Column
                key={col.dataIndex}
                allowEditing={col.editable}
                visible={col.is_visible}
                allowSearch={col.is_searchable}
                allowSorting={col.is_sortable}
                dataField={col.dataIndex}
                caption={col.title}
                setCellValue={function (rowData, value) {
                  this.defaultSetCellValue(rowData, value);
                  if (col.dataIndex === 'masterCountryId') {
                    rowData['masterOrgVerticalId'] = null;
                  }
                  if (col.hasOwnProperty('bindedTo')) {
                    rowData[col.bindedTo] = null;
                  }
                }}
              >
                <Lookup
                  allowClearing
                  dataSource={(options) => {
                    return getCountriesByDivisionOrEntity(options);
                  }}
                  displayExpr="label"
                  valueExpr="id"
                />
                {col.required ? <RequiredRule /> : null}
              </Column>
            );
          } else if (col.dataIndex === 'masterOrgVerticalId') {
            return (
              <Column
                key={col.dataIndex}
                allowEditing={col.editable}
                visible={col.is_visible}
                allowSearch={col.is_searchable}
                allowSorting={col.is_sortable}
                dataField={col.dataIndex}
                caption={col.title}
                setCellValue={function (rowData, value) {
                  rowData['masterOrgVerticalId'] = null;
                  this.defaultSetCellValue(rowData, value);
                  if (col.hasOwnProperty('bindedTo')) {
                    rowData[col.bindedTo] = null;
                  }
                }}
              >
                <Lookup
                  allowClearing
                  dataSource={(options) => {
                    return getVerticalsByEntityAndDivision(options);
                  }}
                  displayExpr="label"
                  valueExpr="id"
                />
                {col.required ? <RequiredRule /> : null}
              </Column>
            );
          } else {
            return (
              <Column
                key={col.dataIndex}
                allowEditing={col.editable}
                visible={col.is_visible}
                allowSearch={col.is_searchable}
                allowSorting={col.is_sortable}
                dataField={col.dataIndex}
                caption={col.title}
                setCellValue={function (rowData, value) {
                  this.defaultSetCellValue(rowData, value);
                  if (col.hasOwnProperty('bindedTo')) {
                    rowData[col.bindedTo] = null;
                  }
                }}
              >
                <Lookup
                  allowClearing
                  dataSource={(options) => {
                    return {
                      store:
                        dropDownData && dropDownData.hasOwnProperty(col.dataIndex)
                          ? dropDownData[col.dataIndex]
                          : [],
                      filter: options.data
                        ? [col.filtrationKey, '=', options.data[col.filtrationKey]]
                        : null
                    };
                  }}
                  displayExpr="label"
                  valueExpr="id"
                />
                {col.required ? <RequiredRule /> : null}
              </Column>
            );
          }
        }
      } else if (col.type === 'multi-select') {
        return (
          <Column
            key={col.dataIndex}
            // {...(valueToFetch !== 'org-entity-clients' && {width:250})}
            editorOptions={{ dropDownOptions: { width: 'auto' } }}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
            editCellComponent={CustomDTagThisComp}
            cellTemplate={(container, options) => {
              const noBreakSpace = '\u00A0';
              const text = Array.isArray(options.value)
                ? (options.value || [])
                    .map((element) => options.column.lookup.calculateCellValue(element))
                    .join(', ')
                : '';
              container.textContent = text || noBreakSpace;
              container.title = text;
            }}
            calculateFilterExpression={function (filterValue, selectedFilterOperation, target) {
              if (target === 'search' && typeof filterValue === 'string') {
                return [col.dataIndex, 'contains', filterValue];
              }
              return function (data) {
                return (data[col.dataIndex] || []).indexOf(filterValue) !== -1;
              };
            }}
          >
            <Lookup
              allowClearing
              dataSource={
                dropDownData && dropDownData.hasOwnProperty(col.dataIndex)
                  ? dropDownData[col.dataIndex]
                  : null
              }
              displayExpr="label"
              valueExpr="id"
            />
            {col.required ? <RequiredRule /> : null}
          </Column>
        );
      } else if (col.type === 'checkbox') {
        return (
          <Column
            key={col.dataIndex}
            dataType="boolean"
            showEditorAlways={true}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
            setCellValue={function (rowData, value) {
              this.defaultSetCellValue(rowData, value);
            }}
          >
            {col.required ? <RequiredRule /> : null}
          </Column>
        );
      } else if (col.type === 'actions') {
        return (
          <Column
            key={col.dataIndex}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            type="buttons"
            dataField={col.dataIndex}
            caption={col.title}
            fixed={false}
          >
            <Button
              name="delete"
              icon={'trash'}
              visible={(e) => e && e.row && e.row.data && e.row.data.newRow === true}
            />
            <Button
              hint="Clone"
              icon="copy"
              visible={(e) =>
                permissions && permissions.canCreate && valueToFetch !== 'entity-country-hfm'
              }
              onClick={(e) => cloneIconClick(e, dataSource, setDataSource)}
            />
          </Column>
        );
      } else if (col.type === 'date') {
        return (
          <Column
            key={col.dataIndex}
            alignment={'left'}
            dataType={'date'}
            editCellComponent={CustomDateBox}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            format={'yyyy-MM-dd'}
            caption={col.title}
          >
            {col.required ? <RequiredRule /> : null}
            {col.dataIndex === 'endDate' ? (
              <AsyncRule
                message="end date cannot be less than start date"
                validationCallback={async (e) => {
                  if (e && e.data) {
                    if (e.data.startDate && e.data.endDate) {
                      return e?.data?.startDate ? e.data.endDate >= e.data.startDate : true;
                    } else
                      return e?.value && e.data?.startDate ? e.value >= e.data.startDate : true;
                  }
                }}
              />
            ) : null}
          </Column>
        );
      } else if (col.type === 'int') {
        return (
          <Column
            key={col.dataIndex}
            dataType={col.type}
            /* editCellComponent={CustomNumberBox} */ allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
            editCellComponent={(props) => (
              <CustomNumberBox props={props.data} canEdit={col.editable} />
            )}
          >
            {col.required ? <RequiredRule /> : null}
            {col.dataIndex !== 'id' ? (
              <AsyncRule
                message={'Value should not exceed more than 15 digits'}
                validationCallback={async (e) => {
                  return e && e.value && e.value.toString().length <= 15;
                }}
              />
            ) : null}
          </Column>
        );
      } else if (col.type === 'file') {
        return (
          <Column
            key={col.dataIndex}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            type={'buttons'}
            fixed={false}
            dataField={col.dataIndex}
            caption={col.title}
            editCellComponent={CustomFileInput}
          />
        );
      } else if (col.type === 'textarea') {
        return (
          <Column
            key={col.dataIndex}
            editCellComponent={CustomTextArea}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
          >
            {col.required ? <RequiredRule /> : null}
            <AsyncRule {...sanitizeAsyncRule} />
          </Column>
        );
      } else if (col.type === 'string') {
        return (
          <Column
            key={col.dataIndex}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
          >
            {col.required ? <RequiredRule /> : null}
            <AsyncRule {...sanitizeAsyncRule} />
          </Column>
        );
      } else {
        return (
          <Column
            key={col.dataIndex}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
          >
            {col.required ? <RequiredRule /> : null}
          </Column>
        );
      }
    }

    /**
     * @param props
     * custom component to display multi select box
     **/
    const CustomDTagThisComp = (props) => {
      const isClientRelated = props?.data?.data?.isClientRelated;
      const disabledOptions =
        isClientRelated && valueToFetch === 'business-category-hfm-group-client-restatement';
      const selectedValues = props?.data?.value || [];
      const [updatedDataSource, setUpdatedDataSource] = useState([]);

      useEffect(() => {
        const initialDataSource =
          dropDownData && dropDownData.hasOwnProperty(props.data.column.dataField)
            ? dropDownData[props.data.column.dataField]
            : [];

        setUpdatedDataSource(initialDataSource);
      }, [props.data.column.dataField, dropDownData]);

      function onValueChanged(e) {
        const newSelectedValues = e.value || [];

        props.data.setValue(newSelectedValues);

        if (disabledOptions) {
          if (newSelectedValues.length > 0) {
            setUpdatedDataSource((prevDataSource) =>
              prevDataSource.map((option) => ({
                ...option,
                disabled: !newSelectedValues.includes(option.id)
              }))
            );
          } else {
            setUpdatedDataSource((prevDataSource) =>
              prevDataSource.map((option) => ({
                ...option,
                disabled: false
              }))
            );
          }
        } else {
          setUpdatedDataSource((prevDataSource) =>
            prevDataSource.map((option) => ({
              ...option,
              disabled: false
            }))
          );
        }
      }

      function onSelectionChanged() {
        props.data.component.updateDimensions();
      }

      function onOpened() {
        if (disabledOptions && selectedValues?.length) {
          setUpdatedDataSource((prevDataSource) =>
            prevDataSource.map((option) => ({
              ...option,
              disabled: !selectedValues.includes(option.id)
            }))
          );
        }
      }

      const tagBoxProps = {
        dropDownOptions: { width: 'auto' },
        dataSource: updatedDataSource,
        defaultValue: props.data.value,
        valueExpr: 'id',
        displayExpr: 'label',
        showSelectionControls: disabledOptions ? false : true,
        maxDisplayedTags: 3,
        showMultiTagOnly: false,
        applyValueMode: 'instantly',
        selectAllMode: 'allPages',
        searchEnabled: true,
        onValueChanged: onValueChanged,
        onSelectionChanged: onSelectionChanged,
        onOpened: onOpened
      };

      return <TagBox {...tagBoxProps} />;
    };

    /**
     * @param options
     * function use get verticals based on division and entity
     **/
    function getVerticalsByEntityAndDivision(options) {
      let uniqueVerticals = [];

      if (
        options &&
        options.data &&
        options.data.hasOwnProperty('masterOrgDivisionId') &&
        options.data.masterOrgDivisionId !== null &&
        options.data.hasOwnProperty('masterOrgEntityId') &&
        options.data.masterOrgEntityId !== null &&
        options.data.hasOwnProperty('masterCountryId') &&
        options.data.masterCountryId !== null
      ) {
        const filteredVerticals =
          dropDownData['masterOrgVerticalId'].filter(
            (obj) =>
              obj.masterCountryId === options.data?.masterCountryId &&
              obj.masterOrgEntityId === options.data?.masterOrgEntityId &&
              obj.masterOrgDivisionId === options.data?.masterOrgDivisionId
          ) ?? [];
        uniqueVerticals = [
          ...new Map(filteredVerticals?.map((item) => [item['id'], item])).values()
        ];
      } else if (
        options &&
        options.data &&
        options.data.hasOwnProperty('masterOrgDivisionId') &&
        options.data.masterOrgDivisionId !== null &&
        options.data.hasOwnProperty('masterOrgEntityId') &&
        options.data.masterOrgEntityId === null &&
        options.data.hasOwnProperty('masterCountryId') &&
        options.data.masterCountryId !== null
      ) {
        const filteredVerticals =
          dropDownData['masterOrgVerticalId'].filter(
            (obj) =>
              obj.masterCountryId === options.data?.masterCountryId &&
              obj.masterOrgDivisionId === options.data?.masterOrgDivisionId
          ) ?? [];
        uniqueVerticals = [
          ...new Map(filteredVerticals?.map((item) => [item['id'], item])).values()
        ];
      } else if (
        options &&
        options.data &&
        options.data.hasOwnProperty('id') &&
        options.data.masterOrgDivisionId !== null &&
        options.data.hasOwnProperty('masterOrgEntityId') &&
        options.data.masterOrgEntityId !== null
      ) {
        const filteredVerticals =
          dropDownData['masterOrgVerticalId'].filter(
            (obj) =>
              obj.masterOrgEntityId === options.data?.masterOrgEntityId &&
              obj.masterOrgDivisionId === options.data?.masterOrgDivisionId
          ) ?? [];
        uniqueVerticals = [
          ...new Map(filteredVerticals?.map((item) => [item['masterOrgVerticalId'], item])).values()
        ];
      } else if (isObjectEmpty(options)) {
        const filteredVerticals =
          dropDownData && dropDownData.hasOwnProperty('masterOrgVerticalId')
            ? dropDownData['masterOrgVerticalId']
            : [];
        uniqueVerticals = [
          ...new Map(filteredVerticals?.map((item) => [item['id'], item])).values()
        ];
      } else {
        const filteredVerticals =
          dropDownData['masterOrgVerticalId'].filter(
            (obj) => obj.masterOrgDivisionId === options.data?.masterOrgDivisionId
          ) ?? [];
        uniqueVerticals = [
          ...new Map(filteredVerticals?.map((item) => [item['id'], item])).values()
        ];
      }

      // if (options && options.data && options.data.masterOrgDivisionId !== null && options.data.masterOrgEntityId !== null && options.data.masterCountryId !== null)
      // {
      //   const filteredVerticals = dropDownData['masterOrgVerticalId'].filter(obj => obj.uniqueKey === `${options.data?.masterOrgDivisionId} - ${options.data?.masterOrgEntityId} - ${options.data?.masterCountryId}`) ?? []
      //   uniqueVerticals = [...new Map(filteredVerticals?.map(item => [item['id'], item])).values()];
      // }
      // else if (options && options.data && options.data.masterOrgDivisionId !== null && options.data.masterOrgEntityId !== null && options.data.masterCountryId === null)
      // {
      //   const filteredVerticals = dropDownData['masterOrgVerticalId'].filter(obj => obj.masterOrgEntityId === options.data?.masterOrgEntityId && obj.masterOrgDivisionId === options.data?.masterOrgDivisionId) ?? []
      //   uniqueVerticals = [...new Map(filteredVerticals?.map(item => [item['id'], item])).values()];
      // }
      // else if (options && options.data && options.data.masterOrgDivisionId !== null && options.data.masterOrgEntityId === null &&  options.data.masterCountryId !== null)
      // {
      //   const filteredVerticals = dropDownData['masterOrgVerticalId'].filter(obj => obj.masterCountryId === options.data?.masterCountryId && obj.masterOrgDivisionId === options.data?.masterOrgDivisionId) ?? []
      //   uniqueVerticals = [...new Map(filteredVerticals?.map(item => [item['id'], item])).values()];
      // }
      // else if (options && options.data && options.data.masterOrgDivisionId !== null && options.data.masterOrgEntityId === null && options.data.masterCountryId === null)
      // {
      //   const filteredVerticals = dropDownData['masterOrgVerticalId'].filter(obj => obj.masterOrgDivisionId === options.data?.masterOrgDivisionId) ?? []
      //   uniqueVerticals = [...new Map(filteredVerticals?.map(item => [item['id'], item])).values()];
      // }
      // else
      // {
      //   const filteredVerticals = dropDownData['masterOrgVerticalId'].filter(obj => obj.masterOrgDivisionId === options.data?.masterOrgDivisionId) ?? []
      //   uniqueVerticals = [...new Map(filteredVerticals?.map(item => [item['uniqueKey'], item])).values()];
      // }

      return uniqueVerticals.length > 0
        ? uniqueVerticals.sort((a, b) =>
            a?.label?.toLowerCase().localeCompare(b?.label?.toLowerCase())
          )
        : [];
    }

    /**
     * @param options
     * function use get countries by division or entity
     **/
    function getCountriesByDivisionOrEntity(options) {
      let uniqueCountries = [];
      if (
        options &&
        options.data &&
        options.data.hasOwnProperty('masterOrgDivisionId') &&
        options.data.masterOrgDivisionId !== null &&
        options.data.hasOwnProperty('masterOrgEntityId') &&
        options.data.masterOrgEntityId !== null
      ) {
        const filteredVerticals =
          dropDownData['masterCountryId'].filter(
            (obj) =>
              obj.uniqueKey ===
              `${options.data.masterOrgDivisionId} - ${options.data.masterOrgEntityId}`
          ) ?? [];
        uniqueCountries = [
          ...new Map(filteredVerticals?.map((item) => [item['id'], item])).values()
        ];
      } else if (
        (options &&
          options.data &&
          options.data.hasOwnProperty('masterOrgDivisionId') &&
          options.data.masterOrgDivisionId !== null &&
          !options.data.hasOwnProperty('masterOrgEntityId')) ||
        options.data?.masterOrgEntityId === null
      ) {
        const filteredVerticals =
          dropDownData['masterCountryId'].filter(
            (obj) => obj.masterOrgDivisionId === options.data?.masterOrgDivisionId
          ) ?? [];
        uniqueCountries = [
          ...new Map(filteredVerticals?.map((item) => [item['id'], item])).values()
        ];
      } else {
        const filteredVerticals =
          dropDownData && dropDownData.hasOwnProperty('masterCountryId')
            ? dropDownData['masterCountryId']
            : [];
        uniqueCountries = [
          ...new Map(filteredVerticals?.map((item) => [item['id'], item])).values()
        ];
      }

      return uniqueCountries.length > 0
        ? uniqueCountries.sort((a, b) =>
            a?.label?.toLowerCase().localeCompare(b?.label?.toLowerCase())
          )
        : [];
    }

    /**
     * @param options
     * function use get Entities by division
     **/
    function getEntitiesByDivision(options) {
      let uniqueEntities = [];
      if (
        options &&
        options.data &&
        options.data.hasOwnProperty('masterOrgDivisionId') &&
        options.data.masterOrgDivisionId !== null
      ) {
        const filteredEntities =
          dropDownData['masterOrgEntityId']?.filter(
            (d) => d.masterOrgDivisionId === options.data?.masterOrgDivisionId
          ) ?? [];
        uniqueEntities = [...new Map(filteredEntities?.map((item) => [item['id'], item])).values()];
      } else {
        const filteredEntities =
          dropDownData && dropDownData.hasOwnProperty('masterOrgEntityId')
            ? dropDownData['masterOrgEntityId']
            : [];
        uniqueEntities = [...new Map(filteredEntities?.map((item) => [item['id'], item])).values()];
      }

      return uniqueEntities.length > 0
        ? uniqueEntities.sort((a, b) =>
            a?.label?.toLowerCase().localeCompare(b?.label?.toLowerCase())
          )
        : [];
    }

    /**
     * @param e
     * initialize new row in the data-grid
     **/
    const onInitNewRow = (e) => {
      window.scrollTo(0, 0);
      e.data.approved = true;
      e.data.status = true;
      e.data.newRow = true;
      if (valueToFetch === 'level') e.data.approved = true;
      if (valueToFetch === 'business-category')
      {
        e.data.isClientRelated = true;
        e.data.isExpenseRelated = false;
      }
      if (valueToFetch && valueToFetch === 'master-rf-budget-lines') {
        e.data.isTBF = false;
        e.data.isINB = false;
      }

      if (valueToFetch === 'decvl') {
        e.data.masterOrgDivisionId = null;
        e.data.masterOrgEntityId = null;
        e.data.masterCountryId = null;
        e.data.masterOrgVerticalId = null;
      }

      setAutoWidth(false);
      setAddEditMode(true);
    };

    /**
     * function use to call the api to post data
     **/
    const pushData = async () => {
      let newData = [...dataSource];
      const dupCheck = hasDuplicateObjects(newData);
      const hasCode = dataColumns.some((obj) => obj.dataIndex === 'code');

      if (dupCheck) {
        toast.error('Duplicate combinations selected. Please remove!');
      } else if (
        valueToFetch &&
        valueToFetch !== 'decvl' &&
        valueToFetch !== 'entity-country-vertical-function' &&
        valueToFetch !== 'entity-country-hfm' &&
        valueToFetch !== 'level' &&
        valueToFetch !== 'org-entity-clients' &&
        hasDuplicates(newData, 'name')
      ) {
        toast.error(`Duplicate entry found for NAME. It cannot be duplicated!`);
      } else if (
        valueToFetch &&
        valueToFetch !== 'decvl' &&
        valueToFetch !== 'entity-country-vertical-function' &&
        valueToFetch !== 'entity-country-hfm' &&
        valueToFetch !== 'level' &&
        valueToFetch !== 'org-entity-clients' &&
        hasCode &&
        hasDuplicates(newData, 'code')
      ) {
        toast.error(`Duplicate entry found for CODE. It cannot be duplicated!`);
      } else {
        showDialogBox(
          async () => {
            await postData(newData, valueToFetch);
            setHasDataChanged(false);
            setSelectedRowKeys([]);
          },
          'info',
          'Yes, continue',
          null,
          'Please ensure to change the workflow steps with the updated values to execute workflows!'
        );
      }
    };

    /**
     * @param e
     * function use to prepare toolbar
     **/
    function onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift(
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'orderedlist',
            text: 'MANAGE SEQUENCE',
            visible:
              permissions &&
              permissions.canCreate &&
              valueToFetch === 'practice-detail-type-budget-lines',
            onClick: function () {
              setSequenceModalVisible(true);
            }
          }
        },
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'upload',
            text: 'BULK UPLOAD',
            visible: permissions && permissions.canCreate && allowBulkUploading,
            onClick: function () {
              setBulkUploadModalVisible(true);
            }
          }
        },
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'save',
            text: 'SUBMIT',
            disabled: !hasDataChanged,
            visible: permissions && permissions.canCreate && (allowAdding || showButton),
            onClick: function () {
              pushData();
            }
          }
        }
      );
    }

    /**
     * @param e
     * Manage pre api call to save data and validation
     **/
    function onSave(e) {
      if (e && e.changes.length) {
        if (e.changes[0].type === 'remove') {
          const dsCopy = [...dataSource];
          const filteredDs = dsCopy.length ? dsCopy.filter((ds) => ds.id !== e.changes[0].key) : [];
          if (filteredDs && filteredDs.length) {
            setDataSource(filteredDs);
            setHasDataChanged(true);
          } else {
            setDataSource([]);
            setHasDataChanged(false);
          }
        } else {
          const dd = e.changes[0].data;
          let finalData = [];
          finalData.push(dd);
          let result = [];
          if (dataSource && dataSource.length) {
            result = _.unionBy(finalData, dataSource);
          } else result.push(dd);

          setDataSource(result);
          setHasDataChanged(true);
        }
      }
      setAutoWidth(true);
      setAddEditMode(false);
    }

    /**
     * @param e
     * validate row before saving
     **/
    function onRowValidating(e) {
      if (e && e.isValid) {
        if (e.newData) {
          const d = [...dataSource];

          if (valueToFetch === 'decvl') {
            const {
              masterLevelId,
              masterOrgDivisionId,
              masterOrgEntityId,
              masterCountryId,
              masterOrgVerticalId
            } = e.newData;
            const f = d.filter(
              (s) =>
                s.id !== e.key &&
                s.masterLevelId === masterLevelId &&
                s.masterOrgDivisionId === masterOrgDivisionId &&
                s.masterOrgEntityId === masterOrgEntityId &&
                s.masterCountryId === masterCountryId &&
                s.masterOrgVerticalId === masterOrgVerticalId
            );
            if (f && f.length) {
              e.isValid = false;
              e.errorText = 'Duplicate Combination Found';
            }
          }

          if (valueToFetch === 'level') {
            const f = d.filter(
              (s) => s.id !== e.key && s.code === e.newData.code && s.name === e.newData.name
            );
            if (f && f.length) {
              e.isValid = false;
              e.errorText = 'Duplicate Combination Found for Code - Name';
            }
          }
        }
      }
    }

    /**
     * function used to handle delete part of data-grid
     **/
    const manageDelete = async () => {
      if (hasValue > 0) {
        toast.error(`${hasValue} records cannot be deleted as it exists in a relationship!`);
      } else {
        const newRecords = dataSource.filter((ds) => ds.hasOwnProperty('newRow'));
        let newData = [...dataSource];
        handleDeleteRequest(async () => {
          const deleteFromApi = selectedRowKeys.filter(
            (a) => a.hasOwnProperty('id') && !a.hasOwnProperty('newRow')
          );
          const deleteFromTable = selectedRowKeys.filter((a) => a.hasOwnProperty('newRow'));
          const result = deleteFromApi.map((a) =>
            valueToFetch === 'org-entity-clients' ? a.masterOrgEntityId : a.id
          );
          if (deleteFromApi.length > 0 && deleteFromTable.length > 0) {
            await handleDelete(result, valueToFetch);
          } else if (deleteFromApi && deleteFromApi.length > 0) {
            structureManagement.rows = newRecords;
            structureManagement.apiDelete = true;
            dispatch({
              type: SET_STRUCTURE_MANAGEMENT,
              payload: structureManagement
            });
            await handleDelete(result, valueToFetch);
          } else if (deleteFromTable && deleteFromTable.length > 0) {
            deleteFromTable.map((a) => {
              newData = newData.filter((item) => item.key !== a.key);
            });
            setDataSource(newData);
          }
          setHasDataChanged(false);
          setSelectedRowKeys([]);
        });
      }
    };

    /**
     * custom function using useMemo to avoid re-renders unless the states listed are changed
     **/
    const Comp = useMemo(() => {
      try {
        return (
          <div
            id="data-grid-demo"
            className="__font__family__regular __data__grid__container __data__grid__with__search"
          >
            {hasSelected > 0 ? (
              <Fragment>
                <br />
                <div color="light" className="__data__grid__alert">
                  <h4 className="__font__size__14 __font__family__regular __mb__0 __mr__15">
                    {`Selected ${selectedRowKeys.length} ${
                      selectedRowKeys.length === 1 ? 'item' : 'items'
                    }`}
                  </h4>
                  <div style={{ height: '30px', borderLeft: '2px solid #999', margin: '0 10px' }} />
                  <div className="__alert__button__container">
                    {permissions && permissions.canDelete && allowDeletingFromApi && (
                      <div className="__alert__button">
                        {/* <Button icon="trash" /> */}
                        <DEButton
                          className="__font__family__regular __default__button__layout __font__size__12 __btn__red"
                          stylingMode={'contained'}
                          type={'danger'}
                          icon="trash"
                          onClick={() => manageDelete()}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Fragment>
            ) : (
              ''
            )}

            <BulkUploaderModal
              title={'Structure Management - Bulk Upload'}
              isModalVisible={bulkUploadModalVisible}
              setIsModalVisible={setBulkUploadModalVisible}
              bulkUploadApi={bulkUploadApi}
              apiCallback={apiCallback}
              tableName={tableName}
              downloadLink={uploadTemplateLink}
              orgStructureLink={orgStructureLink}
              valueToFetch={valueToFetch}
            />

            <SequenceModal
              title={'Manage Sequence - Practice Detail Type and Budget Lines'}
              isSequenceModalVisible={isSequenceModalVisible}
              setSequenceModalVisible={setSequenceModalVisible}
              apiCallback={apiCallback}
              tableName={tableName}
            />

            <DataGrid
              id="grid"
              onCellClick={handleCellClick}
              onToolbarPreparing={onToolbarPreparing}
              showBorders={true}
              columnAutoWidth={autoWidth}
              onSaved={onSave}
              showColumnLines={true}
              showRowLines={true}
              rowAlternationEnabled={true}
              ref={dataGridRef}
              onInitNewRow={onInitNewRow}
              onSelectionChanged={onSelectionChanged}
              allowColumnResizing={true}
              disabled={isLoading}
              dataSource={dataSource}
              wordWrapEnabled={true}
              key="id"
              keyExpr="id"
              onEditorPreparing={onEditorPreparing}
              onRowValidating={onRowValidating}
              onExporting={(e) => CustomDataGridExport(e, dataColumns, dropDownData, tableName)}
            >
              <Paging defaultPageSize={25} />
              <Pager
                visible={true}
                showNavigationButtons={true}
                showInfo={true}
                displayMode={'full'}
              />
              {addEditMode ? null : <Scrolling showScrollbar="always" mode="standard" />}

              {allowSelection ? (
                <Selection
                  allowSelectAll={true}
                  mode="multiple"
                  selectAllMode={'page'}
                  showCheckBoxesMode={'always'}
                />
              ) : null}
              <HeaderFilter visible={true} allowSearch={true} />
              <SearchPanel visible={true} />
              <Export enabled={true} allowExportSelectedData={true} />
              <Editing
                newRowPosition={'first'}
                refreshMode={'repaint'}
                mode="cell"
                allowUpdating={permissions && permissions.canCreate && allowUpdating}
                allowAdding={permissions && permissions.canCreate && allowAdding}
                allowDeleting={allowDeleting}
              />
              {dataColumns && dataColumns.length
                ? dataColumns.map((d) => renderField(d, dropDownData))
                : null}
            </DataGrid>
          </div>
        );
      } catch (e) {}
    }, [
      dataSource,
      dataColumns,
      dropDownData,
      hasDataChanged,
      selectedRowKeys,
      data,
      isLoading,
      autoWidth,
      addEditMode,
      structureManagement,
      bulkUploadModalVisible,
      isSequenceModalVisible
    ]);

    return (
      <Fragment>
        <DetectNavigationBlocker
          setIsDataChanged={setHasDataChanged}
          isDataChanged={hasDataChanged}
        />
        <CustomSkelton>{Comp}</CustomSkelton>
      </Fragment>
    );
  }
);

export default StructureManagementDataGrid;
