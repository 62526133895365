import { useEffect, useContext, useState, useRef, Fragment } from 'react';
import Loadable from 'react-loadable';
import { Switch } from 'devextreme-react/switch';
import * as React from 'react';
import { TagBox } from 'devextreme-react/tag-box';
import { Button, TextBox } from 'devextreme-react';
// import Steps from 'rc-steps';
// import 'rc-steps/assets/index.css';
import TextArea from 'devextreme-react/text-area';
import DateBox from 'devextreme-react/date-box';
import { NumberBox } from 'devextreme-react/number-box';
import { Validator, RequiredRule } from 'devextreme-react/validator';
import { Context } from 'utils/context/store/Store';
import 'assets/datatable-css/stepCss.css';
import SelectBox from 'devextreme-react/select-box';
import useHeadcountRequest from 'utils/hooks/useHeadcountRequest';
import Grid from '@mui/material/Grid';
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import 'assets/datatable-css/datagrid.css';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Fade from '@mui/material/Fade';
import { JsonEditor } from 'react-jsondata-editor';
import { isJSONValid, isObjectEmpty } from 'utils/services/Helpers';
import HtmlEditor, { Toolbar, MediaResizing, Item } from 'devextreme-react/html-editor';
import ColorBox from 'devextreme-react/color-box';
import moment from 'moment';
import { STEPS_STATUS } from '../utils/constant';
import Skelton from './Skelton/defaultSkelton';
const ClientVitalDetailModal = Loadable({
  loader: () => import('./Modal/ClientVitalDetail'),
  loading: () => <Skelton />
});
const Steps = Loadable({
  loader: () => import('./Steps'),
  loading: () => null
});
const CustomFileUploader = Loadable({
  loader: () => import('./CustomFileUploader'),
  loading: () => null
});
import RadioGroup from 'devextreme-react/radio-group';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
const _ = require('lodash');

export const CustomDTag = (props) => {
  useEffect(() => { }, [props]);

  function onValueChanged(e) {
    props.data.setValue(e.value);
  }

  function onSelectionChanged() {
    props.data.component.updateDimensions();
  }

  return (
    <TagBox
      readOnly={!props?.data?.item?.column?.allowEditing || props?.data?.data?.status}
      // disabled={!props.data.item.column.allowEditing || props.data.data.status}
      dataSource={props.data.column.lookup.dataSource}
      defaultValue={props.data.value}
      valueExpr="id"
      displayExpr={'label'}
      showSelectionControls={true}
      maxDisplayedTags={3}
      showMultiTagOnly={false}
      applyValueMode="instantly"
      searchEnabled={true}
      onValueChanged={onValueChanged}
      onSelectionChanged={onSelectionChanged}
    />
  );
};

export const FormTagBox = (props, hcData, dropDownData, canEdit, showAllTags = false) => {
  function onValueChanged(e) {
    props.data.editorOptions.setValue(e.value, props?.data?.name);
  }

  const disabled = hcData.hasOwnProperty('lineManager') && hcData.lineManager === null;

  return (
    <>
      <TagBox
        {...(props?.data?.name === 'current_master_clients' ? { placeholder: '' } : {})}
        dropDownOptions={{ width: 'auto' }}
        readOnly={
          hcData?.canInitiatorEditAfterApproval
            ? false
            : !canEdit || hcData?.status || props.data.editorOptions.disabled || disabled
        }
        dataSource={dropDownData?.[props?.data?.name]}
        defaultValue={hcData?.[props?.data?.name]}
        valueExpr="id"
        displayExpr={'label'}
        showSelectionControls={true}
        maxDisplayedTags={showAllTags ? Infinity : 3}
        showMultiTagOnly={false}
        applyValueMode="instantly"
        searchEnabled={true}
        onValueChanged={onValueChanged}
      />
      {props?.data?.name === 'master_clients' ? (
        <p
          style={{
            fontStyle: 'italic',
            marginTop: '10px',
            whiteSpace: 'initial',
            textWrap: 'initial',
            fontSize: '12px'
          }}
        >
          Can't find your client? Please submit your Client Legal Name and Country{' '}
          <a
            href="https://apps.powerapps.com/play/e/default-d52c9ea1-7c21-47b1-82a3-33a74b1f74b8/a/d8cc0e5c-2b2e-409a-a1cb-c2459d63f91c?tenantId=d52c9ea1-7c21-47b1-82a3-33a74b1f74b8"
            target={'_blank'}
          >
            here
          </a>
        </p>
      ) : null}
    </>
  );
};

export const CustomFileInput = (props) => {
  const [{ hcRequestType }, dispatch] = useContext(Context);
  const ref = React.useRef(null);
  const [render, setRender] = useState(null);
  useEffect(() => { }, [render]);
  function getDesc() {
    const masterRequestTypeId = props.data.data.hasOwnProperty('masterRequestTypeId')
      ? props.data.data.masterRequestTypeId
      : props.data.data?.transaction_headcount_group?.masterRequestTypeId;
    const masterHeadcountTypeId = props.data.data.hasOwnProperty('masterHeadcountTypeId')
      ? props.data.data.masterHeadcountTypeId
      : props.data.data?.transaction_headcount_group?.masterHeadcountTypeId;
    const hcType =
      hcRequestType &&
      hcRequestType.length &&
      hcRequestType.filter((obj) => obj.id === masterRequestTypeId)[0]?.master_headcount_types;
    const hc = hcType && hcType.length && hcType.filter((e) => e.id === masterHeadcountTypeId);

    return hc && hc.length && hc[0]['attachmentDescription'];
  }

  function onValueChanged(e) {
    props.data.setValue(e);
  }

  const text = {
    fontSize: '14px'
  };

  if (
    (props.data.data.hasOwnProperty('newRow') && props.data.data.newRow) ||
    (props.data.data.hasOwnProperty('isDraft') && props.data.data.isDraft)
  ) {
    return (
      <>
        <FilePond
          required={true}
          disabled={!props.data.column.allowEditing || props.data.data.status}
          ref={ref}
          files={props.data.value}
          allowMultiple={true}
          onupdatefiles={onValueChanged}
          labelIdle='Drag & Drop your files or <span className="filepond--label-action">Browse</span>'
        />
        <br />
        <p
          style={{ fontStyle: 'italic', fontSize: '12px' }}
          dangerouslySetInnerHTML={{ __html: getDesc() }}
        />
        {props.data.data?.attachments?.length ? (
          <List
            sx={{
              width: '100%',
              maxWidth: 360,
              paddingBottom: 5,
              whiteSpace: 'normal',
              wordWrap: 'break-word'
            }}
          >
            {props.data.data.attachments.map((v) => (
              <ListItem
                key={v.id}
                // disableGutters
                secondaryAction={
                  <>
                    <IconButton aria-label="download" href={v?.fileUrl}>
                      <FileDownloadIcon />
                    </IconButton>
                    <IconButton
                      aria-label="cross"
                      onClick={(e) => {
                        const newArray = props.data.data.attachments.filter((f) => f.id !== v.id);
                        setRender(newArray.length);
                        props.data.data.attachments = newArray;
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </>
                }
              >
                <ListItemText primaryTypographyProps={{ style: text }} primary={v?.filePath} />
              </ListItem>
            ))}
          </List>
        ) : null}
      </>
    );
  } else if (
    props.data.item.dataField === 'approver_attachments' &&
    props.data.data.hasOwnProperty('canApprove') &&
    props.data.data.hasOwnProperty('needApproval') &&
    props.data.data.canApprove &&
    props.data.data.needApproval
  ) {
    return (
      <>
        <FilePond
          required={true}
          disabled={!props.data.column.allowEditing || props.data.data.status}
          ref={ref}
          files={props.data.value}
          allowMultiple={true}
          onupdatefiles={onValueChanged}
          labelIdle='Drag & Drop your files or <span className="filepond--label-action">Browse</span>'
        />
        <br />
        <p
          style={{ fontStyle: 'italic', fontSize: '12px' }}
          dangerouslySetInnerHTML={{ __html: getDesc() }}
        />
      </>
    );
  } else {
    if (props.data.data?.attachments && props.data.data?.attachments.length) {
      return (
        <List
          sx={{
            width: '100%',
            maxWidth: 360,
            paddingBottom: 5,
            whiteSpace: 'normal',
            wordWrap: 'break-word'
          }}
        >
          {props.data.data.attachments.map((v) => (
            <ListItem
              key={v.id}
              // disableGutters
              secondaryAction={
                <IconButton aria-label="download" href={v?.fileUrl}>
                  <FileDownloadIcon />
                </IconButton>
              }
            >
              <ListItemText primaryTypographyProps={{ style: text }} primary={v?.filePath} />
            </ListItem>
          ))}
        </List>
      );
    } else if (props.data.value && props.data.value.length) {
      return (
        <List
          sx={{
            width: '100%',
            maxWidth: 360,
            paddingBottom: 5,
            whiteSpace: 'normal',
            wordWrap: 'break-word'
          }}
        >
          {props.data.value.map((v) => (
            <ListItem
              key={v.id}
              // disableGutters
              secondaryAction={
                <IconButton aria-label="download" href={v?.fileUrl}>
                  <FileDownloadIcon />
                </IconButton>
              }
            >
              <ListItemText primaryTypographyProps={{ style: text }} primary={v?.filePath} />
            </ListItem>
          ))}
        </List>
      );
    } else {
      return null;
    }
  }
};

export const CustomFormFileInput = (props, hcData = null) => {
  const ref = React.useRef(null);
  const [{ hcRequestType }, dispatch] = useContext(Context);
  function getDesc() {
    const masterRequestTypeId = hcData?.transaction_headcount_group?.masterRequestTypeId;
    const masterHeadcountTypeId = hcData?.transaction_headcount_group?.masterHeadcountTypeId;
    const hcType =
      hcRequestType &&
      hcRequestType.length &&
      hcRequestType.filter((obj) => obj.id === masterRequestTypeId)[0]?.master_headcount_types;
    const hc = hcType && hcType.length && hcType.filter((e) => e.id === masterHeadcountTypeId);

    return hc?.length > 0 && hc[0]['attachmentDescription'];
  }

  const text = {
    fontSize: '14px',
    wordBreak: 'break-all'
  };

  function onValueChanged(e) {
    props.data.editorOptions.setValue(e);
  }

  if (props?.data && props.data?.dataField === 'approver_attachments') {
    return (
      <>
        <FilePond
          required={true}
          disabled={props.data?.editorOptions?.disabled}
          ref={ref}
          files={props?.data?.editorOptions?.value}
          allowMultiple={true}
          onupdatefiles={onValueChanged}
          labelIdle='Drag & Drop your files or <span className="filepond--label-action">Browse</span>'
        />
        <p
          style={{ textWrap: 'initial', fontStyle: 'italic', fontSize: '12px' }}
          dangerouslySetInnerHTML={{ __html: getDesc() || '' }}
        />
      </>
    );
  } else if (
    props?.data &&
    props.data?.editorOptions &&
    props.data.editorOptions?.value &&
    props.data.editorOptions.value?.length > 0
  ) {
    return (
      <ul className="__attachment__listing__">
        {props.data.editorOptions.value.map(({ filePath, fileUrl }, index) => (
          <li className="__remove__marker" key={index}>
            <span>{filePath}</span>
            <a href={fileUrl}>
              <i className="dx-icon-download"></i>
            </a>
          </li>
        ))}
      </ul>
    );

    return (
      <List
        sx={{ width: 'fit-content', paddingTop: 1, whiteSpace: 'normal', wordWrap: 'break-word' }}
      >
        {props.data.editorOptions.value.map((v) => (
          <ListItem
            key={v.id}
            secondaryAction={
              <IconButton aria-label="download" href={v?.fileUrl}>
                <FileDownloadIcon />
              </IconButton>
            }
          >
            <ListItemText primaryTypographyProps={{ style: text }} primary={v?.filePath} />
          </ListItem>
        ))}
      </List>
    );
  } else {
    return null;
  }
};

export const FormFileUploader = (props, hcData) => {
  const [filesValue, setFilesValue] = useState([]);
  const [attachmentFiles, setAttachmentFiles] = useState(
    hcData?.attachments ?? hcData?.attachment ?? []
  );
  const [{ hcRequestType }, dispatch] = useContext(Context);

  function getDesc() {
    if (hcData?.masterRequestTypeId && hcData?.masterHeadcountTypeId) {
      const hcType =
        hcRequestType &&
        hcRequestType.length &&
        hcRequestType.filter((obj) => obj.id === hcData?.masterRequestTypeId)[0]
          ?.master_headcount_types;
      const hc =
        hcType && hcType.length && hcType.filter((e) => e.id === hcData?.masterHeadcountTypeId);
      return hcData?.hasOwnProperty('replacementRequired')
        ? null
        : hc?.length > 0 && hc[0]['attachmentDescription'];
    }
  }

  useEffect(() => {
    const newFilesValue = hcData?.[props?.data?.name] ?? [];
    const newAttachmentFiles =
      hcData?.attachments?.length > 0
        ? hcData?.attachments
        : hcData?.attachment?.length > 0
          ? hcData?.attachment
          : [];

    setFilesValue((prev) => {
      return JSON.stringify(prev) !== JSON.stringify(newFilesValue) ? newFilesValue : prev;
    });

    setAttachmentFiles((prev) => {
      return JSON.stringify(prev) !== JSON.stringify(newAttachmentFiles)
        ? newAttachmentFiles
        : prev;
    });
  }, [hcData, props?.data?.name]);

  const text = {
    fontSize: '14px'
  };

  function onValueChanged(e) {
    props.data.editorOptions.setValue(e.value, props?.data?.name);
    setFilesValue(e.value);
  }

  if (
    !props.data.editorOptions.disabled &&
    (hcData?.canInitiatorEditAfterApproval ||
      (hcData.hasOwnProperty('newRow') && hcData?.newRow) ||
      (hcData.hasOwnProperty('isDraft') && hcData?.isDraft))
  ) {
    return (
      <>
        <CustomFileUploader
          filesValue={filesValue}
          onValueChanged={onValueChanged}
          disabled={props.data.editorOptions.disabled}
          selectButtonText={'Select a file'}
          isRequired={!!props?.data?.editorOptions?.isRequired}
        />

        <p
          style={{
            position: 'relative',
            top: '8px',
            padding: '0 4px',
            whiteSpace: 'initial',
            overflowWrap: 'initial',
            fontStyle: 'italic',
            fontSize: '12px'
          }}
          dangerouslySetInnerHTML={{ __html: getDesc() || '' }}
        />

        {attachmentFiles?.length &&
          !hcData?.newRow &&
          props?.data?.name !== 'approver_attachments' ? (
          <>
            <ul className="__attachment__listing__">
              {attachmentFiles.map(({ filePath, fileUrl, isDeleted }, index) =>
                !isDeleted ? (
                  <li className="__remove__marker" key={`attachment-${index}`}>
                    <span>{filePath}</span>
                    <a href={fileUrl}>
                      <i className="dx-icon-download"></i>
                    </a>
                    <a
                      onClick={() => {
                        const att = attachmentFiles.map((file) =>
                          file.filePath === filePath ? { ...file, isDeleted: true } : file
                        );
                        setAttachmentFiles(att);
                        hcData.attachments = att;
                      }}
                    >
                      <i className="dx-icon-trash"></i>
                    </a>
                  </li>
                ) : null
              )}
            </ul>
            {/* TODO remove once tested okay */}
            {false && (
              <List sx={{ width: 'fit-content', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                {attachmentFiles?.map((v) =>
                  !v.hasOwnProperty('isDeleted') ? (
                    <ListItem key={v.id}>
                      <ListItemText
                        primaryTypographyProps={{ style: text }}
                        primary={v?.filePath}
                      />
                      <IconButton aria-label="download" href={v?.fileUrl}>
                        <FileDownloadIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          const att = attachmentFiles.map((file) =>
                            file.filePath === v.filePath ? { ...file, isDeleted: true } : file
                          );
                          setAttachmentFiles(att);
                          hcData.attachments = att;
                        }}
                      >
                        <DeleteOutlinedIcon />
                      </IconButton>
                    </ListItem>
                  ) : null
                )}
              </List>
            )}
          </>
        ) : null}
      </>
    );
  } else if (attachmentFiles && attachmentFiles?.length) {
    return (
      <ul className="__attachment__listing__">
        {attachmentFiles.map(({ filePath, fileUrl }, index) => (
          <li className="__remove__marker" key={`attachment-${index}`}>
            <span>{filePath}</span>
            <a href={fileUrl}>
              <i className="dx-icon-download"></i>
            </a>
          </li>
        ))}
      </ul>
    );
    return (
      <List
        sx={{ width: 'fit-content', paddingTop: 1, whiteSpace: 'normal', wordWrap: 'break-word' }}
      >
        {attachmentFiles.map((v) => (
          <ListItem
            key={v.id}
            secondaryAction={
              <IconButton aria-label="download" href={v?.fileUrl}>
                <FileDownloadIcon />
              </IconButton>
            }
          >
            <ListItemText primaryTypographyProps={{ style: text }} primary={v?.filePath} />
          </ListItem>
        ))}
      </List>
    );
  } else {
    return null;
  }
};

export const CustomSimpleTextArea = (props) => {
  const propObject = props?.data ?? null;
  const valueRef = useRef(null);
  const id = propObject?.column?.dataField?.split('-')?.[1];

  useEffect(() => {
    if (propObject.data[`orgComments-${id}`] && propObject.data[`orgComments-${id}`] !== null) {
      valueRef.current = propObject.data[`orgComments-${id}`];
    }
  }, [propObject.value]);

  const onValueChanged = (e) => {
    if (propObject && typeof propObject.setValue === 'function') {
      propObject.setValue(e.value);
    }

    if (valueRef?.current !== e?.value) {
      propObject.data[`changed-${id}`] = true;
    } else {
      delete propObject.data[`changed-${id}`];
    }
  };

  const onInput = (e) => {
    const newValue = e?.event?.target?.value;

    if (propObject && typeof propObject.setValue === 'function') {
      propObject.setValue(newValue);
    }
    propObject.data[`comments-${id}`] = newValue;
  };

  const isTextAreaDisabled = (data, dataIndex) => {
    const suffix = dataIndex.split('-')[1];
    return data[`masterCvLightId-${suffix}`] == null;
  };

  const disabled = isTextAreaDisabled(propObject.data, propObject?.column?.dataField);

  return (
    <TextArea
      disabled={disabled}
      readOnly={!propObject.column.allowEditing}
      defaultValue={propObject.value}
      height={100}
      onValueChanged={onValueChanged}
      onInput={onInput}
      style={{
        backgroundColor: disabled ? '#f0f0f0' : 'inherit',
        color: disabled ? '#a0a0a0' : 'inherit'
      }}
    />
  );
};

export const CustomTextArea = (props) => {
  const masterRequestTypeId = props.data.data.hasOwnProperty('masterRequestTypeId')
    ? props.data.data.masterRequestTypeId
    : props.data.data?.transaction_headcount_group?.masterRequestTypeId;
  function onValueChanged(e) {
    if (props.data && typeof props.data.setValue === 'function') {
      props.data.setValue(e.value);
    }
  }

  return (
    <>
      {props.data.column.dataField === 'description' ? (
        <TextArea
          onKeyDown={(e) => (e.event.keyCode === 13 ? e.event.preventDefault() : e)}
          defaultValue={props.data.value}
          height={100}
          onValueChanged={onValueChanged}
        />
      ) : (
        <TextArea
          hint={props.data.value}
          onKeyDown={(e) => (e.event.keyCode === 13 ? e.event.preventDefault() : e)}
          readOnly={!props.data.column.allowEditing || props.data.data.status}
          defaultValue={props.data.value}
          height={100}
          onValueChanged={onValueChanged}
        />
      )}
      {props.data.column.dataField === 'justification' && masterRequestTypeId !== 2 ? (
        <p
          style={{
            fontStyle: 'italic',
            marginTop: '10px',
            whiteSpace: 'initial',
            textWrap: 'initial',
            fontSize: '12px'
          }}
        >
          Justification is required for all HC requests.
        </p>
      ) : null}
    </>
  );
};

export const FormTextArea = (props, hcData, canEdit) => {
  function onValueChanged(e) {
    props.data.editorOptions.setValue(e.value, props?.data?.name);
  }

  return (
    <>
      {props?.data?.name === 'description' ? (
        <TextArea
          className={props?.data?.editorOptions?.elementAttr?.class}
          onKeyDown={(e) => (e.event.keyCode === 13 ? e.event.preventDefault() : e)}
          defaultValue={hcData?.[props?.data?.name]}
          height={100}
          onValueChanged={onValueChanged}
        />
      ) : (
        <TextArea
          className={props?.data?.editorOptions?.elementAttr?.class}
          onKeyDown={(e) => (e.event.keyCode === 13 ? e.event.preventDefault() : e)}
          readOnly={
            hcData?.canInitiatorEditAfterApproval
              ? false
              : !canEdit || hcData?.status || props.data.editorOptions.disabled
          }
          defaultValue={hcData?.[props?.data?.name]}
          height={100}
          onValueChanged={onValueChanged}
        />
      )}
      {props?.data?.name === 'justification' && hcData?.masterRequestTypeId !== 2 ? (
        <p
          style={{
            fontStyle: 'italic',
            marginTop: '10px',
            whiteSpace: 'initial',
            textWrap: 'initial',
            fontSize: '12px'
          }}
        >
          Justification is required for all HC requests.
        </p>
      ) : null}
    </>
  );
};
export const ScrollableFormTextArea = (props) => {
  return (
    <>
      <TextArea
        onKeyDown={(e) => (e.event.keyCode === 13 ? e.event.preventDefault() : e)}
        defaultValue={props.data.editorOptions.value}
        height={100}
        readOnly={true}
      />
    </>
  );
};

export const CustomTextBox = (props) => {
  function onValueChanged(e) {
    props.data.setValue(e.value);
  }

  return (
    <TextBox
      mode={'text'}
      showClearButton={true}
      readOnly={!props.data.column.allowEditing || props.data.data.status}
      defaultValue={props.data.value}
      onValueChanged={onValueChanged}
    />
  );
};

export const FormTextBox = (props, hcData, canEdit) => {
  function onValueChanged(e) {
    props.data.editorOptions.setValue(e.value, props?.data?.name);
  }

  return (
    <TextBox
      className={props?.data?.editorOptions?.elementAttr?.class}
      mode={'text'}
      showClearButton={true}
      disabled={
        !canEdit ||
        hcData.status ||
        !(
          hcData.newRow ||
          (!hcData.hasRollback && !hcData.isRollback && hcData.isDraft) ||
          (hcData.hasRollback && hcData.isRollback)
        )
      }
      defaultValue={hcData?.[props?.data?.name]}
      onValueChanged={onValueChanged}
      valueChangeEvent="keyup"
    />
  );
};

export const CustomDateBox = (props) => {
  const { hold_reason, cancel_reason, status } = props?.data?.data;
  const isValid =
    (status && status === true && hold_reason && hold_reason !== null) ||
    (cancel_reason && cancel_reason !== null);
  const effectiveDate = new Date(props?.data?.data?.effectiveDate);
  const minDate =
    props?.data?.column?.dataField === 'leaverNotificationDate' ||
      props?.data?.column?.dataField === 'tenureStartDate' ||
      props?.data?.column?.dataField === 'tenureEndDate' ||
      props?.data?.column?.dataField === 'lastWorkingDay' ||
      props?.data?.column?.dataField === 'effectiveDate'
      ? null
      : props?.data?.column?.dataField === 'expirationDate'
        ? effectiveDate
        : new Date();
  const maxDate = props?.data?.column?.dataField === 'leaverNotificationDate' ? new Date() : null;
  const disabled =
    props?.data?.column?.dataField === 'endDate' ||
      props?.data?.column?.dataField === 'startDate' ||
      props?.data?.column?.dataField === 'effectiveDate' ||
      props?.data?.column?.dataField === 'expirationDate' ||
      props?.data?.column?.dataField === 'tenureStartDate' ||
      props?.data?.column?.dataField === 'tenureEndDate' ||
      props?.data?.column?.dataField === 'businessSubmissionDate'
      ? false
      : props?.data?.item?.column?.allowEditing === false ||
      (!props?.data?.data?.needApproval && props?.data?.data?.status);

  function onValueChanged(e) {
    const selectedDate = e.value ? moment(e.value).format('YYYY-MM-DD') : e.value;
    props.data.setValue(selectedDate);
  }

  return (
    <DateBox
      readOnly={disabled}
      displayFormat={'dd-MM-yyyy'}
      showClearButton={true}
      defaultValue={props?.data?.value}
      onValueChanged={onValueChanged}
      type="date"
      pickerType="calendar"
      min={minDate}
      max={maxDate}
      openOnFieldClick={true}
      {...(isValid ? { isValid } : {})}
    />
  );
};

export const CustomYearBox = (props) => {
  const isValid = true
  const minDate = new Date(new Date().getFullYear(), 0, 1)
  const maxDate = null;
  const disabled = false

  function onValueChanged(e) {
    const selectedDate = e.value ? moment(e.value).format('YYYY') : e.value;
    props.data.setValue(selectedDate);
  }

  return (
    <DateBox
      readOnly={disabled}
      displayFormat={'yyyy'}
      showClearButton={true}
      defaultValue={props?.data?.value}
      onValueChanged={onValueChanged}
      type="date"
      pickerType="calendar"
      min={minDate}
      max={maxDate}
      openOnFieldClick={true}
      calendarOptions={{ maxZoomLevel: 'decade', minZoomLevel: 'decade' }}
      {...(isValid ? { isValid } : {})}
    />
  );
};

export const FormDateBox = (props, hcData, canEdit, isFormDetails = false) => {
  const { hold_reason, cancel_reason, status } = hcData;
  const isValid =
    (status && status === true && hold_reason && hold_reason !== null) ||
    (cancel_reason && cancel_reason !== null);
  const nextDate = hcData?.startDate ? new Date(hcData?.startDate) : null;
  const minDate =
    props?.data?.name === 'leaverNotificationDate' || props?.data?.name === 'lastWorkingDay'
      ? null
      : new Date();
  const maxDate = props?.data?.name === 'leaverNotificationDate' ? new Date() : null;
  const disabled = !canEdit;
  const maxSelectableDate = nextDate ? nextDate.setMonth(nextDate.getMonth() + 3) : null;

  function onValueChanged(e) {
    const selectedDate = e.value ? moment(e.value).format('YYYY-MM-DD') : e.value;
    props.data.editorOptions.setValue(selectedDate, props?.data?.name);
  }

  return (
    <DateBox
      className={props?.data?.editorOptions?.elementAttr?.class}
      disabled={
        disabled ||
        !(
          hcData?.status ||
          hcData.newRow ||
          (!hcData.hasRollback && !hcData.isRollback && hcData.isDraft) ||
          (hcData.hasRollback && hcData.isRollback)
        )
      }
      displayFormat={'dd-MM-yyyy'}
      showClearButton={isFormDetails ? false : true}
      defaultValue={hcData?.[props?.data?.name]}
      onValueChanged={onValueChanged}
      type="date"
      pickerType="calendar"
      min={minDate}
      max={props?.data?.name === 'endDate' ? maxSelectableDate : maxDate}
      openOnFieldClick={true}
      {...(isValid ? { isValid } : {})}
    />
  );
};

export const CustomJsonEditor = (props) => {
  function onValueChanged(e) {
    props.data.setValue(e);
  }

  return (
    <JsonEditor
      jsonObject={JSON.stringify(isJSONValid(props.data.value))}
      onChange={onValueChanged}
    />
  );
};

export const SelectJD = ({ props, canEdit }) => {
  const [jdId, setJdId] = useState(null);
  const [jdDetails, setJdDetails] = useState({});
  const { getJDByCombination } = useHeadcountRequest();
  const [{ hcJdView }, dispatch] = useContext(Context);

  useEffect(async () => {
    if (
      Array.isArray(hcJdView) &&
      !hcJdView?.length &&
      props?.data.hasOwnProperty('masterOrgDivisionId') &&
      props?.data?.masterOrgDivisionId !== null &&
      props?.data.hasOwnProperty('masterLevelId') &&
      props?.data?.masterLevelId !== null &&
      props?.data.hasOwnProperty('masterOrgVerticalId') &&
      props?.data?.masterOrgVerticalId !== null &&
      props?.data.hasOwnProperty('masterOrgFunctionId') &&
      props?.data?.masterOrgFunctionId !== null
    ) {
      const combination = {
        masterOrgDivisionId: props?.data?.masterOrgDivisionId,
        masterOrgEntityId: props?.data?.masterOrgEntityId,
        masterCountryId: props?.data?.masterCountryId,
        masterOrgVerticalId: props?.data?.masterOrgVerticalId,
        masterOrgFunctionId: props?.data?.masterOrgFunctionId,
        masterLevelId: props?.data?.masterLevelId
      };
      await getJDByCombination(combination, false, null);
    }

    if (hcJdView?.length > 0 && props && props?.value !== undefined) {
      setJdId(props.value);
      const existingJd = hcJdView.find((j) => j.id === props?.value);
      setJdDetails(existingJd);
    } else {
      setJdId(undefined);
      setJdDetails({});
    }
  }, [props?.data?.masterLevelId]);

  useEffect(() => {
    // if (props && props.data && props.data.hasOwnProperty('jd_transaction') && props.data.jd_transaction !== null) {
    //   const existingJd = hcJdView && hcJdView.find(j => j.id === props.data.jd_transaction.id)
    //   setJdDetails(existingJd ?? {
    //     "id": props.data.jd_transaction.id,
    //     "jobCode": props.data.jd_transaction.jobCode,
    //     "version": props.data.jd_transaction.version,
    //     "value": props.data.jd_transaction.id,
    //     "label": props.data.jd_transaction.jobName,
    //     "transactionJdId": props.data.jd_transaction.id,
    //     "jobName": props.data.jd_transaction.jobName,
    //     "minRange": 0
    //   })
    //   if (!existingJd) {
    //     dispatch({
    //       type: SET_HC_JD_VIEW,
    //       payload: [{
    //         "id": props.data.jd_transaction.id,
    //         "jobCode": props.data.jd_transaction.jobCode,
    //         "version": props.data.jd_transaction.version,
    //         "value": props.data.jd_transaction.id,
    //         "label": props.data.jd_transaction.jobName,
    //         "transactionJdId": props.data.jd_transaction.id,
    //         "jobName": props.data.jd_transaction.jobName,
    //         "minRange": 0
    //       }]
    //     })
    //   }
    // }
  }, [jdId]);

  useEffect(() => { }, [jdDetails]);

  function onValueChanged(e) {
    props.setValue(e.value);
    props.data.transactionJdId = e.value;
    props.data.transactionJdId = e.value;
    setJdDetails(hcJdView.find((j) => j.id === e.value) ?? {});
    setJdId(e.value);
  }

  let isValid = !(
    !hcJdView?.length &&
    (props.value === undefined || props.value === null) &&
    props.data.masterOrgDivisionId !== null &&
    props.data.masterLevelId !== null
  );

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <SelectBox
            readOnly={
              !canEdit ||
              props?.data?.status ||
              props.data.masterOrgDivisionId === null ||
              props.data.masterOrgEntityId === null ||
              props.data.masterCountryId === null ||
              props.data.masterOrgFunctionId === null ||
              props.data.masterLevelId === null
            }
            isValid={isValid}
            noDataText="No JD found with the combination"
            showClearButton={true}
            defaultValue={
              props.data.masterOrgDivisionId !== null &&
                props.data.masterOrgEntityId !== null &&
                props.data.masterCountryId !== null &&
                props.data.masterOrgFunctionId !== null &&
                props.data.masterLevelId !== null
                ? props.value
                : props?.data?.view_jd ?? null
            }
            onValueChanged={onValueChanged}
            displayExpr="label"
            valueExpr="id"
            dataSource={hcJdView && !isObjectEmpty(hcJdView) ? hcJdView : []}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            disabled={
              jdId === null ||
              isObjectEmpty(jdDetails) ||
              props.data.masterOrgDivisionId === null ||
              props.data.masterOrgEntityId === null ||
              props.data.masterCountryId === null ||
              props.data.masterOrgFunctionId === null ||
              props.data.masterLevelId === null
            }
            stylingMode={'contained'}
            type={'success'}
            text={'View JD'}
            onClick={() =>
              window.open(
                `/views/jd/Job-${jdDetails?.jobCode ?? props?.data?.jobCode}/${jdDetails?.version ?? props?.data?.version
                }`,
                '_blank'
              )
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export const FormSelectJD = (props, hcData, hcJdView, canEdit, setJdDet) => {
  const [jdDetails, setJdDetails] = useState({});

  useEffect(async () => {
    if (hcJdView?.length && hcData && hcData?.transactionJdId !== undefined) {
      const existingJd = hcJdView.find((j) => j.id === hcData?.transactionJdId);
      setJdDetails(existingJd);
    } else {
      setJdDetails({});
    }
  }, [hcData?.masterLevelId]);

  function onValueChanged(e) {
    props.data.editorOptions.setValue(e.value);
    setJdDetails(hcJdView.find((j) => j.id === e.value) ?? {});
    setJdDet(hcJdView.find((j) => j.id === e.value) ?? {});
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={9.5}>
          <SelectBox
            disabled={
              hcData?.canInitiatorEditAfterApproval
                ? hcData?.masterOrgDivisionId === null ||
                hcData?.masterOrgEntityId === null ||
                hcData?.masterCountryId === null ||
                hcData?.masterOrgFunctionId === null ||
                hcData?.masterLevelId === null
                : !canEdit ||
                hcData?.status ||
                hcData?.masterOrgDivisionId === null ||
                hcData?.masterOrgEntityId === null ||
                hcData?.masterCountryId === null ||
                hcData?.masterOrgFunctionId === null ||
                hcData?.masterLevelId === null ||
                !(
                  hcData.newRow ||
                  (!hcData.hasRollback && !hcData.isRollback && hcData.isDraft) ||
                  (hcData.hasRollback && hcData.isRollback)
                )
            }
            isValid={hcData?.transactionJdId !== undefined || hcData?.transactionJdId !== null}
            noDataText="No JD found with the combination"
            showClearButton={true}
            defaultValue={
              hcData?.masterOrgDivisionId !== null &&
                hcData?.masterOrgEntityId !== null &&
                hcData?.masterCountryId !== null &&
                hcData?.masterOrgFunctionId !== null &&
                hcData?.masterLevelId !== null
                ? hcData?.transactionJdId
                : hcData?.view_jd ?? null
            }
            onValueChanged={onValueChanged}
            displayExpr="label"
            valueExpr="id"
            dataSource={hcJdView && !isObjectEmpty(hcJdView) ? hcJdView : []}
          >
            <Validator>
              <RequiredRule message="" />
            </Validator>
          </SelectBox>
        </Grid>
        <Grid item xs={2.5}>
          <Button
            width={'100%'}
            disabled={
              isObjectEmpty(jdDetails) ||
              hcData?.masterOrgDivisionId === null ||
              hcData?.masterOrgEntityId === null ||
              hcData?.masterCountryId === null ||
              hcData?.masterOrgFunctionId === null ||
              hcData?.masterLevelId === null
            }
            stylingMode={'contained'}
            type={'success'}
            text={'View JD'}
            onClick={() =>
              window.open(
                `/views/jd/Job-${jdDetails?.jobCode ?? hcData?.jobCode}/${jdDetails?.version ?? hcData?.version
                }`,
                '_blank'
              )
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export const CustomSelectBox = (props, hcData, dropDownData, disabled, callback) => {
  function onValueChanged(e) {
    props.data.editorOptions.setValue(e.value, props?.data?.name);
  }

  return (
    <>
      <SelectBox
        disabled={disabled}
        showClearButton={true}
        defaultValue={hcData?.[props?.data?.name]}
        onValueChanged={onValueChanged}
        displayExpr="name"
        valueExpr="id"
        dataSource={{
          paginate: true,
          pageSize: 10,
          store: dropDownData?.[props?.data?.name] ?? [] ?? []
        }}
      />
      {props?.data?.name === 'masterEmployeeId' ? (
        <p
          style={{
            fontStyle: 'italic',
            marginTop: '10px',
            whiteSpace: 'initial',
            textWrap: 'initial',
            fontSize: '12px'
          }}
        >
          If the employee is transitioning internally, please refrain from adding the leaver and
          ensure to coordinate with the TA Lead as they are responsible for managing internal
          mobility cases on TEOS.
        </p>
      ) : null}
    </>
  );
};

export const FormViewJD = (props, hcData, hcJdView) => {
  const [jdDetails, setJdDetails] = useState({});

  useEffect(async () => {
    if (hcJdView?.length && hcData && hcData?.transactionJdId !== undefined) {
      const existingJd = hcJdView.find((j) => j.id === hcData?.transactionJdId);
      setJdDetails(existingJd);
    } else {
      setJdDetails({});
    }
  }, []);

  function onValueChanged(e) {
    props.data.editorOptions.setValue(e.value, props?.data?.name);
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={9.5}>
          <TextBox
            mode={'text'}
            showClearButton={true}
            disabled={props.data.editorOptions.disabled}
            defaultValue={hcData.jobName}
            onValueChanged={onValueChanged}
          />
        </Grid>
        <Grid item xs={2.5}>
          <Button
            width={'100%'}
            disabled={jdDetails.length}
            stylingMode={'contained'}
            type={'success'}
            text={'View JD'}
            onClick={() =>
              window.open(
                `/views/jd/Job-${jdDetails?.jobCode ?? hcData?.jobCode}/${jdDetails?.version ?? hcData?.version
                }`,
                '_blank'
              )
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export const CustomNumberBox = ({ props, canEdit = true, isForHc = false }) => {
  let disabled = false;
  let isValid = true;
  if (props.column.dataField === 'budget') {
    disabled = !canEdit;
  } else if (props.column.dataField === 'revenue') {
    disabled = !canEdit || props.data.status;
  } else {
    !canEdit || props.data.status;
  }

  function onValueChanged(e) {
    props.setValue(e.value);
  }

  if (
    isForHc &&
    props.data.masterBudgetStatusId !== null &&
    props.data.masterBudgetStatusId !== 1 &&
    props.data.budget === null
  ) {
    isValid = false;
  }

  return (
    <NumberBox
      isValid={isValid}
      readOnly={isForHc && (!canEdit || props.data.status || props.data.masterBudgetStatusId !== 2)}
      min={props.column.dataField === 'rate' ? 0.01 : 0}
      defaultValue={props.value}
      showSpinButtons={true}
      showClearButton={true}
      onValueChanged={onValueChanged}
    />
  );
};

export const CustomColorPicker = ({ data, canEdit = true }) => {
  function onValueChanged(e) {
    data.data.setValue(e.value);
  }

  return (
    <ColorBox
      defaultValue={data.data.value}
      applyValueMode="instantly"
      onValueChanged={onValueChanged}
      disabled={!canEdit}
    />
  );
};

export const ProgressSteps = ({ steps = [], isHorizontalSteps = false }) => {
  steps = steps.map((step) => {
    return {
      ...step,
      status:
        step.currentStatus === null
          ? step.isCurrentStep
            ? STEPS_STATUS.PROCESS.text
            : STEPS_STATUS.WAIT.text
          : step.currentStatus
            ? STEPS_STATUS.FINISH.text
            : STEPS_STATUS.ERROR.text,
      tailContent: step.approverName ? (
        <div className="rc-steps-item-approvername">{step.approverName}</div>
      ) : null
    };
  });

  return (
    <>
      <div className={`step-progress ${isHorizontalSteps ? '__horizontal__steps__' : ''}`}>
        {steps.map((step, index) => (
          <Fragment key={`progress-steps-${index}`}>
            <Steps
              title={step.title}
              approverName={step.approverName}
              subtitle={step.subTitle}
              status={step.status}
              description={step.description}
              isActive={step.status === 'Approved'}
              canShowStepLine={index < steps.length - 1}
            />
          </Fragment>
        ))}
      </div>
      {/* TODO remove once tested okay */}
      {/* <Steps
        className={isOverflow ? 'overflow-steps' : ''}
        type="default"
        labelPlacement={'vertical'}
        size="default"
        status={status}
        current={index < 0 ? steps.length - 1 : index}
        direction={'horizontal'}
        items={steps}
      /> */}
    </>
  );
};

export const CustomSwitch = (props) => {
  function onValueChanged(e) {
    props.data.setValue(e.value);
  }

  return (
    <Switch
      // defaultValue={props.data.value ?? false}
      value={props.data.value}
      onValueChanged={onValueChanged}
    />
  );
};

export const CustomHTMLEditor = (props) => {
  const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
  const fontValues = [
    'Arial',
    'Courier New',
    'Georgia',
    'Impact',
    'Lucida Console',
    'Tahoma',
    'Times New Roman',
    'Verdana'
  ];
  const headerValues = [false, 1, 2, 3, 4, 5];

  function onValueChanged(e) {
    props.data.setValue(e.value);
  }

  return (
    <div className="widget-container">
      <HtmlEditor
        onValueChanged={onValueChanged}
        height="725px"
        width="auto"
        defaultValue={props.data.value}
      >
        <MediaResizing enabled={true} />
        <Toolbar multiline={false}>
          <Item name="bold" />
          <Item name="italic" />
          <Item name="underline" />
          <Item name="separator" />
          <Item name="alignLeft" />
          <Item name="alignCenter" />
          <Item name="alignRight" />
          <Item name="alignJustify" />
          <Item name="separator" />
          <Item name="orderedList" />
          <Item name="bulletList" />
          {/* // TODO remove once confirmed */}
          {/* <Item name="undo" />
        <Item name="redo" />
        <Item name="separator" />
        <Item name="size" acceptedValues={sizeValues} />
        <Item name="font" acceptedValues={fontValues} />
        <Item name="separator" />
        <Item name="bold" />
        <Item name="italic" />
        <Item name="strike" />
        <Item name="underline" />
        <Item name="separator" />
        <Item name="alignLeft" />
        <Item name="alignCenter" />
        <Item name="alignRight" />
        <Item name="alignJustify" />
        <Item name="separator" />
        <Item name="orderedList" />
        <Item name="bulletList" />
        <Item name="separator" />
        <Item name="header" acceptedValues={headerValues} />
        <Item name="separator" />
        <Item name="color" />
        <Item name="background" />
        <Item name="separator" />
        <Item name="link" />
        <Item name="image" />
        <Item name="separator" />
        <Item name="clear" />
        <Item name="codeBlock" />
        <Item name="blockquote" />
        <Item name="separator" />
        <Item name="insertTable" />
        <Item name="deleteTable" />
        <Item name="insertRowAbove" />
        <Item name="insertRowBelow" />
        <Item name="deleteRow" />
        <Item name="insertColumnLeft" />
        <Item name="insertColumnRight" />
        <Item name="deleteColumn" /> */}
        </Toolbar>
      </HtmlEditor>
    </div>
  );
};

export const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    TransitionComponent={Fade}
    TransitionProps={{ timeout: 150 }}
    className={className}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 800,
    fontSize: theme.typography.pxToRem(14),
    color: '#000000',
    backgroundColor: '#ffffff',
    padding: '7px',
    border: '1px solid #dadde9',
    boxShadow: '0px 0px 6px gray'
  }
}));

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    TransitionComponent={Fade}
    TransitionProps={{ timeout: 150 }}
    className={className}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 250,
    fontSize: theme.typography.pxToRem(14),
    color: '#000000',
    backgroundColor: '#ffffff',
    padding: '6px',
    border: '1px solid #dadde9',
    boxShadow: '0px 0px 6px gray',
    textAlign: 'left'
  }
}));

export const RenderCvItemsData = ({ props, dropdownValue, tabValue }) => {
  const renderLights = (lights, activeLight) => {
    return lights
      ?.sort((a, b) => a.sequence - b.sequence)
      .map((light) => {
        let itemClass = 'light',
          itemStyle = { border: `2px solid ${light.color}` };

        if (light.id === activeLight) {
          itemClass = 'light active';
          itemStyle = { backgroundColor: light.color };
        }
        return <span key={light.id} style={itemStyle} className={itemClass}></span>;
      });
  };

  function renderColor(lights, activeLight, withColorName = false) {
    const lightData = lights ? lights.find((light) => light.id === activeLight) : null;
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            width: '20px',
            height: '20px',
            borderRadius: '4px',
            backgroundColor: lightData?.color ? lightData.color : 'transparent',
            border: lightData?.color ? '' : '1px solid #A2A2A2',
            marginRight: '8px'
          }}
        ></div>
        <div>{withColorName && lightData?.name ? lightData.name : null}</div>
      </div>
    );
  }

  const renderHistoryItems = (historyTransactionItems) => {
    return (
      <div className={'CvHistoryTransactionTable CustomDatagridTable'}>
        <p>Status</p>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>KPI Color</th>
              <th>Comment</th>
              <th>User</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {historyTransactionItems
              ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              ?.map((item, idx) => {
                const isLongComment =
                  item.comment &&
                  (item.comment.split(/\s+/).length > 84 || item.comment.length > 100);

                return (
                  <tr key={idx}>
                    <td>{historyTransactionItems.length - idx}</td>
                    <td>
                      {item.lights && item.activeLight
                        ? renderColor(item.lights, item.activeLight, true)
                        : null}
                    </td>
                    <td className={`comment-cell ${isLongComment ? 'scrollable' : ''}`}>
                      {item.comment}
                    </td>
                    <td>{item.createdBy?.name}</td>
                    <td>
                      {item.createdAt ? moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss') : null}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  };

  return props?.value?.length ? (
    <div
      className="cvItemsContainer"
      style={{
        pointerEvents: !props.data.status ? 'none' : 'all',
        backgroundColor: !props.data.status ? '#f4f2f2' : ''
      }}
    >
      {props.value
        ?.sort((a, b) => a.sequence - b.sequence)
        .map(
          (
            {
              id,
              masterClientId,
              masterDepartmentId,
              name,
              comment,
              lights,
              activeLight,
              createdBy,
              createdAt,
              historyTransactionItems
            },
            idx
          ) => (
            <ClientVitalDetailModal
              key={`client-vital-detail-modal-${idx}`}
              idx={idx}
              title={
                historyTransactionItems.length ? renderHistoryItems(historyTransactionItems) : null
              }
              renderHistoryItems={renderHistoryItems}
              comment={comment}
              lights={lights}
              activeLight={activeLight}
              renderColor={renderColor}
              name={name}
              mClientId={masterClientId}
              mEntityId={masterDepartmentId}
              categoryId={props.columnIndex - 2}
              id={id}
              dropdownValue={dropdownValue}
              viewTabValue={tabValue}
            />
          )
        )}
    </div>
  ) : null;
};

export const CustomRadioGroup = (props, options = []) => {
  function onValueChanged(e) {
    props.data.editorOptions.setValue(e.value, props?.data?.name);
  }

  return (
    <RadioGroup
      items={options}
      displayExpr={(dp) => dp?.toString()?.toUpperCase()}
      defaultValue={props?.data?.editorOptions?.value}
      onValueChanged={onValueChanged}
      layout="horizontal"
    />
  );
};

/**
 * Generates a sort value function for a lookup-based column.
 * @param {Array} lookupData - The array of lookup objects with `id` and `label`.
 * @param {string} lookupField - The field in the data that contains the id to lookup.
 * @returns {Function} A function that returns the sort value based on lookup labels.
 */
export function createLookupSortValueFunction(lookupData, lookupField) {
  return (data) => {
    const item = lookupData ? lookupData.find((item) => item.id === data[lookupField]) : null;
    return item ? item.label : '';
  };
}
