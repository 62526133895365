import React, { useContext, useEffect, useState, useRef } from 'react';
import Loadable from 'react-loadable';
import { Context } from 'utils/context/store/Store';
import usePagePermissions from 'utils/hooks/usePagePermissions';
import Skelton from '../../components/Skelton/defaultSkelton';
import { FileUploader } from 'devextreme-react';
import useIngestActuals from 'utils/hooks/useIngestActuals';
import { toast } from 'react-toastify';

const IngestActualsDatagrid = Loadable({
  loader: () => import('./Datatable/IngestActualsDatagrid'),
  loading: () => <Skelton />
});

function IngestActuals({ routeKey, pageName }) {
  const { ingestActuals, getActualUploadLogs, isLoading } = useIngestActuals(routeKey);
  const [{ ingestActualsLogs }, dispatch] = useContext(Context);
  const { getPermissionsForPage } = usePagePermissions(routeKey);

  const [file, setFile] = useState(null);
  const fileUploaderRef = useRef(null);

  useEffect(async () => {
    await getActualUploadLogs();
  }, []);

  const handleFileChange = (e) => {
    const uploadedFile = e.value[0];
    if (uploadedFile) {
      const fileName = uploadedFile.name;
      const isValid = validateFileName(fileName);

      if (!isValid) {
        toast.error(
          "Invalid file name format. Expected format: 'Actuals_<MM>_<MM>_<YYYY>.xlsx'."
        );
        fileUploaderRef.current.instance.reset();
        setFile(null);
      } else {
        setFile(uploadedFile);
      }
    }
  };

  const validateFileName = (fileName) => {
    const regex = /^Actuals_[A-Z][a-z]{2}_[A-Z][a-z]{2}_\d{4}\.xlsx$/;
    return regex.test(fileName);
  };

  const handleFileUpload = async () => {
    await ingestActuals(file, fileUploaderRef);
  };

  const styles = {
    fileUploaderContainer: {
      border: '1px solid #d3d9de',
      borderRadius: '6px',
      padding: '10px 15px',
      backgroundColor: '#f9fbfc',
      marginBottom: '10px',
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
      position: 'relative'
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '5px'
    },
    heading: {
      margin: 0,
      fontSize: '1.25rem',
      fontWeight: 600,
      color: '#333'
    },
    description: {
      margin: '0 0 10px',
      fontSize: '0.9rem',
      color: '#666',
      lineHeight: 1.4
    },
    uploader: {
      display: 'block',
      marginTop: '5px'
    },
    downloadButton: {
      textDecoration: 'none',
      fontSize: '0.9rem',
      fontWeight: 100,
      color: '#585858',
      backgroundColor: 'rgb(255, 255, 255)',
      padding: '5px 10px',
      border: '1px solid #d3d3d3', // Light grey border
      borderRadius: '4px',
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      display: 'inline-block',
      textAlign: 'center'
    }
  };

  return (
    <div className="__body__section __manage__values__">
      <div className="__base__layout__container">
        <div style={styles.fileUploaderContainer}>
          <div style={styles.header}>
            <h3 style={styles.heading}>Upload File</h3>
            <a
              href={ingestActualsLogs?.uploadTemplate ?? ""}
              target="_blank"
              style={styles.downloadButton}
            >
              Download Template
            </a>
          </div>
          <p style={styles.description}>
            Expected format: <b>Actuals_&lt;MM&gt;_&lt;MM&gt;_&lt;YYYY&gt;.xlsx</b>
          </p>
          <p style={styles.description}>
            Example: <b>Actuals_Jan_Feb_2025.xlsx</b>
          </p>
          <FileUploader
            ref={fileUploaderRef}
            multiple={false}
            accept=".xlsx"
            uploadMode="useButtons"
            onValueChanged={handleFileChange}
            uploadFile={handleFileUpload}
            style={styles.uploader}
          />
        </div>

        <IngestActualsDatagrid
          routeKey={routeKey}
          isLoading={isLoading}
          rows={ingestActualsLogs.rows ?? []}
          columns={ingestActualsLogs.columns}
          permissions={getPermissionsForPage(routeKey)}
          allowSelection={false}
        />
      </div>
    </div>
  );
}

export default IngestActuals;