import React, { memo } from 'react';
import { Link } from '@mui/material';
import { Card, Col } from 'antd';

const style = {
  'text-align': 'center'
};

const DownloadTemplateCard = memo(({ downloadLink, title }) => (
  <div style={{ ...style }}>
    <a href={downloadLink} target="_blank">
      <i class="dx-icon-download" style={{fontSize: '30px'}}></i>
    </a>
    <h4>{title}</h4>
  </div>
));

export default DownloadTemplateCard;
