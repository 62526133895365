import React, { useState, useEffect } from 'react';
import Handsontable from 'handsontable/base';
import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import Grid from '@mui/material/Grid';
import BaseLayOut from '../../../components/Layout/BaseLayOut';
import MDBox from 'components/MDBox';
import { Button } from 'devextreme-react/button';
import SelectBox from 'devextreme-react/select-box';
import useFinancialForecast from '../../../utils/hooks/useFinancialForecast';
import Header from './components/header';
import Versions from './components/versions';
import { getFilteredSelectedTypeHeaderVersionFromList } from './components/utils';
import { isJSONValid } from 'utils/services/Helpers';

registerAllModules();

const HeadersSetup = ({ routeKey, pageName }) => {
  const { getSetupHeader, removeHeaderVersion, alignHeaders } = useFinancialForecast();
  const [isShown, setIsShown] = useState(false);
  const [isResetDropdownVersionValue, setIsResetDropdownVersionValue] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [headerVersions, setHeaderVersions] = useState([]);
  const [selectedTypeHeaderVersions, setSelectedTypeHeaderVersions] = useState([]);
  const [headerType, setHeaderType] = useState([]);
  const [loadSelectedVersionHeader, setLoadSelectedVersionHeader] = useState([]);
  const [header, setHeader] = useState([]);
  const [isHeaderSubmitted, setIsHeaderSubmitted] = useState(true);

  useEffect(async () => {
    if (isHeaderSubmitted) {
      setLoadSelectedVersionHeader([]);
      setIsResetDropdownVersionValue(true);
      const { dropdownValues, data } = await getSetupHeader();
      if (dropdownValues.masterRFHeaderTypeId && dropdownValues.masterRFHeaderTypeId.length > 0) {
        setHeaderType(dropdownValues.masterRFHeaderTypeId);
      }
      if (data && data.length > 0) {
        setHeaderVersions(data);
      }
      setIsHeaderSubmitted(false);
    }
  }, [isHeaderSubmitted]);

  useEffect(() => {
    if (selectedOption !== '' && headerVersions.length > 0) {
      const __headerVersions = headerVersions.filter(
        ({ masterRFHeaderTypeId }) => masterRFHeaderTypeId === selectedOption
      );
      if (__headerVersions && __headerVersions.length > 0) {
        setSelectedTypeHeaderVersions(__headerVersions);
      }
    }
  }, [selectedOption]);

  const handleLoadSelectVersionHeader = (versionId) => {
    if (versionId) {
      const { header } = selectedTypeHeaderVersions.filter(({ id }) => id === versionId)[0];
      setLoadSelectedVersionHeader(isJSONValid(header));
      setIsShown(true);
    }
  };

  const handleCreateNewHeader = () => {
    setLoadSelectedVersionHeader([]);
    setIsResetDropdownVersionValue(true);
    setIsShown(true);
  };

  const removeVersion = (versionId) => {
    const ver = [...getFilteredSelectedTypeHeaderVersionFromList(versionId, headerVersions)];
    setSelectedTypeHeaderVersions(ver);
    setHeaderVersions(ver);
    removeHeaderVersion([versionId]);
  };

  // data source for displaying headers
  // this is required to show handson header
  const dataSource = () => {
    if (header && header.length > 0) {
      const lastRowLength = header[header.length - 1].length;
      const __body = Array(lastRowLength).fill(null);

      return [__body];
    }

    return [];
  };

  return (
    <div className="__body__section __base__layout__container __ff__header__">
      <h3>Financial Forecast Setup</h3>
      <div className="__ff__header__inner">
        <SelectBox
          key={`type-of-header`}
          width={200}
          className="__ff__header__dropdown display-inline-block"
          placeholder={'Type'}
          valueExpr="id"
          displayExpr="name"
          onValueChanged={(e) => {
            setIsShown(false);
            setSelectedTypeHeaderVersions([]);
            setSelectedOption(e.value);
          }}
          value={selectedOption}
          dataSource={headerType}
        />
        {selectedOption && selectedTypeHeaderVersions.length > 0 && (
          <Versions
            selectedTypeHeaderVersions={selectedTypeHeaderVersions}
            handleLoadSelectVersionHeader={handleLoadSelectVersionHeader}
            isResetDropdownVersionValue={isResetDropdownVersionValue}
            setIsResetDropdownVersionValue={setIsResetDropdownVersionValue}
            removeVersion={removeVersion}
          />
        )}
        {selectedOption && (
          <Button
            width={200}
            type="default"
            className="__ff__header__btn __default__button__layout"
            onClick={handleCreateNewHeader}
          >
            <span className="dx-button-text">{`Create New header`}</span>
          </Button>
        )}
      </div>
      {header && (
        <HotTable
          nestedHeaders={header}
          data={dataSource()}
          afterGetColHeader={(e,col, th) => alignHeaders(e, col, th, header)}
          colHeaders={true}
          rowHeaders={false}
          licenseKey="non-commercial-and-evaluation"
        />
      )}
      {isShown && selectedOption && (
        <Header
          setHeader={setHeader}
          header={header}
          headerType={selectedOption}
          loadSelectedVersionHeader={loadSelectedVersionHeader}
          setIsHeaderSubmitted={setIsHeaderSubmitted}
        />
      )}
    </div>
  );
};

export default HeadersSetup;
