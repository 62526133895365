import React, { memo } from 'react';
import { CheckBox } from 'devextreme-react/check-box';
import { TextBox } from 'devextreme-react/text-box';

const TotalOptions = memo(
  ({
    checkboxOne,
    setCheckboxOne,
    checkboxTwo,
    setCheckboxTwo,
    total,
    setTotal,
    totalText,
    setTotalText,
    totalForRows,
    setTotalForRows,
    totalForColumns,
    setTotalForColumns,
    setTooltipForTotal,
    setTooltipForPH,
    setTooltipForBL,
    tooltipForTotal,
    tooltipForBL,
    tooltipForPH
  }) => {
    return (
      <div className="__ff__header__container__total">
        <CheckBox
          className="__ff__header__checkbox"
          text="BL check"
          value={checkboxOne}
          onValueChanged={(e) => setCheckboxOne(e.value)}
        />
        {checkboxOne && (
          <TextBox
            className="display-inline-block __ff__header__textbox"
            width={200}
            label="BL tooltip"
            value={tooltipForBL}
            onKeyUp={(e) => setTooltipForBL(e.event.target.value)}
          />
        )}
        <CheckBox
          className="__ff__header__checkbox"
          text="PH check"
          value={checkboxTwo}
          onValueChanged={(e) => setCheckboxTwo(e.value)}
        />
        {checkboxTwo && (
          <TextBox
            className="display-inline-block __ff__header__textbox"
            width={200}
            label="PH tooltip"
            value={tooltipForPH}
            onKeyUp={(e) => setTooltipForPH(e.event.target.value)}
          />
        )}
        <CheckBox
          className="__ff__header__checkbox"
          text="Total"
          value={total}
          onValueChanged={(e) => setTotal(e.value)}
        />
        {total && (
          <>
            <TextBox
              className="display-inline-block __ff__header__textbox"
              width={200}
              label="text"
              value={totalText}
              onKeyUp={(e) => setTotalText(e.event.target.value)}
            />
            <TextBox
              className="display-inline-block __ff__header__textbox"
              width={200}
              label="Total tooltip"
              value={tooltipForTotal}
              onKeyUp={(e) => setTooltipForTotal(e.event.target.value)}
            />
          </>
        )}
        <CheckBox
          className="__ff__header__checkbox"
          text="Total in rows?"
          value={totalForRows}
          onValueChanged={(e) => setTotalForRows(e.value)}
        />
        <CheckBox
          className="__ff__header__checkbox"
          text="Total in columns?"
          value={totalForColumns}
          onValueChanged={(e) => setTotalForColumns(e.value)}
        />
      </div>
    );
  }
);

export default TotalOptions;
