import { useContext, useEffect, useState } from 'react';
import { getPageProperties } from '../services/Helpers';
import { Context } from '../context/store/Store';
import request from '../services/Http';
import { SET_IS_LOADING } from '../context/store/Constants';
import { useIsLoading } from './useIsLoading';
import * as XLSX from 'xlsx';

export default function useRFReporting(routeKey) {
  const [data, setData] = useState([]);
  const [header, setHeader] = useState([]);
  const [dropDownData, setDropDownData] = useState({});
  const [{ pagesAndPermissions }, dispatch] = useContext(Context)
  const [value, setValue] = useState(null);
  const [selectedValues, setSelectedValues] = useState({});
  const { setIsLoading, isLoading } = useIsLoading();
  const [exportData, setExportData] = useState([])

  useEffect(() => {
    if (value && selectedValues?.masterBudgetCyclePeriodId)
    {
      const fn = async () => await fetchReportByType()
      fn()
    }
  }, [selectedValues]);
  const setPageSelectBoxes = () => {
    const properties = getPageProperties(pagesAndPermissions, routeKey)
    const pageList = properties?.page?.elements?.dropdowns?.ddList || {}
    setDropDownData(prvState => ({
      ...prvState,
      pageList
    }))
  }

  const getReportData = async () => {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.get('rf-report')
      if (res)
      {
        setDropDownData(prevState => ({
          ...prevState,
          ...res.data.dropdownData
        }))
      }
    }
    catch (e) {}
    finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
      setIsLoading(false);
    }
  }

  const fetchReportByType = async () => {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.post('rf-report', {
        ...selectedValues,
        reportType: value
      })
      if (res)
      {
        setData(res.data.data)
        setExportData(res.data.exportData)
        setHeader(res.data.headers)
      }
    }
    catch (e) {}
    finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
      setIsLoading(false);
    }
  }

  const handlePageDropDown = (value) => {
    setSelectedValues({})
    resetStates()
    setValue(value)
    setDropDownData({ pageList: dropDownData.pageList })
  }

  const saveAsExcel = () => {
    try {
      const worksheet = XLSX.utils.json_to_sheet(exportData);
      worksheet["!freeze"] = { xSplit: 0, ySplit: 1 };
      const range = XLSX.utils.decode_range(worksheet['!ref']);
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cell = XLSX.utils.encode_cell({ r: 0, c: col });
        worksheet[cell].s = { locked: true };
      }

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, `${value?.toUpperCase()}`);

      const fileName = `${value?.toUpperCase()}_${new Date().getTime()}.xlsx`;

      XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.error("Error in downloading Excel file:", error);
    }
  };

  const resetStates = () => {
    setData([])
    setHeader([])
    setExportData([])
  }

  const setDropDownValues = (key, value) => {
    let values = {}
    if (key === "masterOrgHFMId")
    {
      values['year'] = null
      values['masterBudgetCyclePeriodId'] = null
      resetStates()
    }
    if (key === "year")
    {
      values['masterBudgetCyclePeriodId'] = null;
      resetStates()
    }
    if (!value)
    {
      resetStates()
    }
    setSelectedValues(prevState => ({
      ...prevState,
      [key]: value,
      ...values
    }))
  }

  return {
    data, dropDownData, setPageSelectBoxes, value, setValue,getReportData, setDropDownValues,
    selectedValues, header, handlePageDropDown, saveAsExcel, exportData
  }
}