import React, { useState, useEffect, memo } from 'react';
import { Modal, Row } from 'antd';
import ScrollView from 'devextreme-react/scroll-view';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { Button } from 'devextreme-react';
import DownloadTemplateCard from './DownloadTemplateCard';
import UploadTemplateCard from './UploadTemplateCard';

const BulkUploaderModal = memo(
  ({
    title,
    isModalVisible,
    setIsModalVisible,
    bulkUploadApi,
    apiCallback,
    tableName,
    downloadLink,
    orgStructureLink = null,
    valueToFetch = null
  }) => {
    const [file, setFile] = useState(null);

    useEffect(() => {
      setFile(null);

      // cleanup on unmount
      return () => {
        setFile(null);
      };
    }, []);

    const handleCancel = () => {
      setIsModalVisible(false);
    };

    const handleFileChange = (event) => {
      setFile(event.value[0]);
    };

    const handleFileUpload = async () => {
      await bulkUploadApi(file, tableName, apiCallback, valueToFetch);
      setIsModalVisible(false);
    };

    return (
      <div>
        <Popup
          className="__font__family recruitment-stage"
          height={'auto'}
          width={900}
          visible={isModalVisible}
          dragEnabled={false}
          onHiding={handleCancel}
          contentRender={() => (
            <ScrollView width="100%" height="100%">
              <div className="__bulk__upload__modal">
                <div className="__download__template__files">
                  <DownloadTemplateCard downloadLink={downloadLink} title={'Download Template'} />
                  {orgStructureLink ? (
                    <DownloadTemplateCard
                      downloadLink={orgStructureLink}
                      title={'Org Structure Template'}
                    />
                  ) : null}
                </div>
                <span
                  style={{
                    border: '0.1px solid',
                    height: '50px',
                    left: '28px',
                    position: 'relative'
                  }}
                ></span>
                <UploadTemplateCard
                  handleFileChange={handleFileChange}
                  handleFileUpload={handleFileUpload}
                />
              </div>
            </ScrollView>
          )}
        >
          <ToolbarItem
            render={() => <h2 className="__font__family __mb__0">{title}</h2>}
            location="center"
            toolbar="top"
          />
        </Popup>
      </div>
    );
  }
);

export default BulkUploaderModal;
