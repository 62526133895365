import request from '../services/Http';
import { useContext, useState } from "react";
import { Context } from "../context/store/Store";
import { SET_INGEST_ACTUALS_LOGS, SET_IS_LOADING } from "../context/store/Constants";
import 'react-toastify/dist/ReactToastify.css';
import { getPageProperties, getTableColumns, handleUploadLogs, handleUploadResult, isJSONValid, uploadFile } from "../services/Helpers";
import { useIsLoading } from "./useIsLoading";

export default function useIngestActuals(routeKey) {

    const [dataSource, setDataSource] = useState(null);
    const [{ pagesAndPermissions }, dispatch] = useContext(Context)
    const { isLoading, setIsLoading } = useIsLoading()

    async function ingestActuals(file, ref = null) {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })

        try {
            const formData = new FormData();
            formData.append('file', file);

            const res = await uploadFile(`ingest-actuals`, formData);

            if (res) {
                handleUploadLogs(res.data.logs, new Date().getTime() + `_RFActualsUploadLogs.xlsx`);
                handleUploadResult(res.data.data);
                await getActualUploadLogs()
                if (ref) ref.current.instance.reset();
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    async function getActualUploadLogs(file, callback) {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })

        try {
            const res = await request.get('ingest-actuals');
            const properties = getPageProperties(pagesAndPermissions, 'ingest-actuals')
            const table = getTableColumns(properties.page.table, 'ingest-actuals')
            let columns = table.columns
            let rows = isJSONValid(res.data.data) ?? []
            let uploadTemplate = res.data.uploadTemplate ?? ""
            dispatch({
                type: SET_INGEST_ACTUALS_LOGS,
                payload: { columns, rows, uploadTemplate }
            });
        } catch (error) {
            console.error('Error getActualUploadLogs:', error);
        }
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    async function getTableDataFromLogId(id) {
        setDataSource(null)
        setIsLoading(true)
        // dispatch({ type: SET_IS_LOADING, payload: true })

        try {
            const res = await request.get(`ingest-actuals/detail/${id}`);
            let rows = isJSONValid(res.data.data) ?? []
            let dropdownValues = isJSONValid(res.data.dropdownValues) ?? []
            setDataSource({ rows, dropdownValues })
        } catch (error) {
            setDataSource(null)
            console.error('Error getTableDataFromLogId:', error);
            setIsLoading(false)
        }
        setIsLoading(false)
        // dispatch({ type: SET_IS_LOADING, payload: false })
    }

    return { ingestActuals, getActualUploadLogs, isLoading, getTableDataFromLogId, dataSource, setDataSource }
}