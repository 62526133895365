import React, { useEffect, useState } from 'react';
import { Button } from 'devextreme-react/button';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import useFinancialForecast from '../../../../utils/hooks/useFinancialForecast';
import Column from './column';
import TotalOptions from './totalOptions';
import Levels from './levelsField';
import { getNumberOfColumnsFromHeader, generateExcelColumnNames } from './utils';
import { headerLevels } from '../../components/constants';
import './styles.scss';

const Header = ({
  setHeader,
  header,
  headerType,
  loadSelectedVersionHeader,
  setIsHeaderSubmitted
}) => {
  const { submitHeader, isLoading } = useFinancialForecast();
  const [levels, setLevels] = useState(headerLevels);
  const [yearStructure, setYearStructure] = useState([]);
  const [checkboxOne, setCheckboxOne] = useState(false);
  const [checkboxTwo, setCheckboxTwo] = useState(false);
  const [total, setTotal] = useState(false);
  const [totalForRows, setTotalForRows] = useState(false);
  const [totalForColumns, setTotalForColumns] = useState(false);
  const [totalText, setTotalText] = useState('');
  const [data, setData] = useState([]);
  const [tooltipForTotal, setTooltipForTotal] = useState('');
  const [tooltipForBL, setTooltipForBL] = useState('');
  const [tooltipForPH, setTooltipForPH] = useState('');

  const addRootItem = () => {
    setData([...data, { label: '', tooltip: '', __child: [], count: 0, uniqueKey: Math.random() }]);
  };

  const buildYearStructure = (data) => {
    const result = [];

    const traverse = (node, level) => {
      if (!result[level]) {
        result[level] = [];
      }

      node.__child.forEach((child) => traverse(child, level + 1));

      const colspan = node.__child.reduce((acc, child) => acc + (child.lastLevelChild || 1), 0);
      node.lastLevelChild = colspan || 1;

      result[level].push({ label: node.label, colspan: colspan || 1, tooltip: node.tooltip ?? '' });
    };

    data.forEach((node) => traverse(node, 0));

    setYearStructure(result);

    return result;
  };

  const addTotalsToStructure = (structure, totalText) => {
    return structure.map((row, i) => {
      if (i === structure.length - 1) {
        return [...row, { label: totalText, colspan: 1, tooltip: tooltipForTotal ?? '' }];
      }
      return [...row, null];
    });
  };

  const addLevelsToStructure = (structure, levels) => {
    const __levels = [];
    levels.forEach(({ key, text, tooltip }) => {
      if (key !== null) {
        __levels.push({ label: text, colspan: 1, tooltip: tooltip ?? '' });
      }
    });
    return structure.map((row, i) => {
      if (i === structure.length - 1) {
        return [...__levels, ...row];
      }
      return [...Array(__levels.length).fill(null), ...row];
    });
  };

  const addCheckboxesToStructure = (structure, { checkboxOne, checkboxTwo }) => {
    const checkboxArr = [];

    if (checkboxOne) checkboxArr.push({ label: 'BL', colspan: 1, tooltip: tooltipForBL ?? '' });
    if (checkboxTwo) checkboxArr.push({ label: 'PH', colspan: 1, tooltip: tooltipForPH ?? '' });

    return structure.map((row, i) => {
      if (i === structure.length - 1) {
        return [...checkboxArr, ...row];
      }
      return [...Array(checkboxArr.length).fill(null), ...row];
    });
  };

  const buildHeaderStructureForHandsOn = (data) => {
    let headerStructure = buildYearStructure(data);
    if (total && totalText) {
      headerStructure = addTotalsToStructure(headerStructure, totalText);
    }

    if (levels.length > 0) {
      headerStructure = addLevelsToStructure(headerStructure, levels);
    }
    
    if (checkboxOne || checkboxTwo) {
      headerStructure = addCheckboxesToStructure(headerStructure, { checkboxOne, checkboxTwo });
    }

    setHeader(headerStructure);
  };

  const resetState = () => {
    setTotal(false);
    setTotalForColumns(false);
    setTotalForRows(false);
    setTooltipForBL('');
    setTooltipForPH('');
    setTooltipForTotal('');
    setTotalText('');
    setLevels(headerLevels);
    setHeader([]);
    setData([]);
    setYearStructure([]);
  };

  useEffect(() => {
    if (loadSelectedVersionHeader && Object.keys(loadSelectedVersionHeader).length > 0) {
      const {
        total,
        totalForColumns,
        totalForRows,
        totalText,
        levels,
        header,
        dataSetup,
        checkboxes,
        checkboxesTooltip
      } = loadSelectedVersionHeader;
      const { checkboxOne, checkboxTwo } = checkboxes || {};
      const { tooltipForBL, tooltipForPH, tooltipForTotal } = checkboxes || {};
      setTotal(total);
      setTotalForColumns(totalForColumns);
      setTotalForRows(totalForRows);
      setTotalText(totalText);
      setLevels(levels);
      setHeader(header);
      setData(dataSetup);
      checkboxOne && setCheckboxOne(checkboxOne);
      checkboxTwo && setCheckboxTwo(checkboxTwo);
      checkboxesTooltip?.tooltipForBL && setTooltipForBL(checkboxesTooltip?.tooltipForBL);
      checkboxesTooltip?.tooltipForPH && setTooltipForPH(checkboxesTooltip?.tooltipForPH);
      checkboxesTooltip?.tooltipForTotal && setTooltipForTotal(checkboxesTooltip?.tooltipForTotal);
    } else {
      resetState();
    }
  }, [loadSelectedVersionHeader]);

  useEffect(() => {
    buildHeaderStructureForHandsOn(data);
  }, [data, levels, totalText, total, checkboxOne, checkboxTwo, tooltipForBL, tooltipForPH, tooltipForTotal]);

  const submit = async () => {
    if (data.length > 0 && header.length > 0) {
      const numberOfColumns = getNumberOfColumnsFromHeader(header);
      const body = [
        {
          masterRFHeaderTypeId: headerType,
          isDefault: false,
          header: {
            numberOfColumns,
            total,
            totalText,
            totalForRows,
            totalForColumns,
            checkboxes: {
              checkboxOne,
              checkboxTwo
            },
            checkboxesTooltip: {
              tooltipForBL,
              tooltipForPH,
              tooltipForTotal,
            },
            header,
            levels,
            dataSetup: data,
            type: headerType,
            columnNames: generateExcelColumnNames(numberOfColumns),
            yearStructure
          }
        }
      ];

      await submitHeader(body);
      setIsHeaderSubmitted(true);
      resetState();
    }
  };

  return (
    <div className="__ff__header__container">
      <Button
        width={250}
        type="success"
        className="__ff__header__btn __default__button__layout __btn__green"
        disabled={isLoading}
        onClick={submit}
      >
        <LoadIndicator className="button-indicator" visible={isLoading} />
        <span className="dx-button-text">{`Submit`}</span>
      </Button>
      <TotalOptions
        checkboxOne={checkboxOne}
        setCheckboxOne={setCheckboxOne}
        checkboxTwo={checkboxTwo}
        setCheckboxTwo={setCheckboxTwo}
        total={total}
        setTotal={setTotal}
        totalText={totalText}
        setTotalText={setTotalText}
        totalForRows={totalForRows}
        setTotalForRows={setTotalForRows}
        totalForColumns={totalForColumns}
        setTotalForColumns={setTotalForColumns}
        setTooltipForTotal={setTooltipForTotal}
        setTooltipForPH={setTooltipForPH}
        setTooltipForBL={setTooltipForBL}
        tooltipForTotal={tooltipForTotal}
        tooltipForBL={tooltipForBL}
        tooltipForPH={tooltipForPH}
      />
      <Levels setLevels={setLevels} levels={levels} />
      <Button
        width={250}
        type="default"
        className="__ff__header__btn __default__button__layout"
        text={`Add Column`}
        onClick={addRootItem}
      />
      <Column updateData={setData} data={data} />
    </div>
  );
};

export default Header;
