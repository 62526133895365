import React, { useState } from 'react';
import Loadable from 'react-loadable';
import Skelton from '../../components/Skelton/defaultSkelton';
import { Tooltip } from 'devextreme-react/tooltip';
const WelcomeUserInfo = Loadable({
  loader: () => import('../../components/WelcomeUserInfo'),
  loading: () => <Skelton />
});
const BlackSectionController = Loadable({
  loader: () => import('../../components/BlackSectionController'),
  loading: () => <Skelton />
});
const QuickLinks = Loadable({
  loader: () => import('../../components/QuickLinks'),
  loading: () => <></>
});
const MyTasks = Loadable({
  loader: () => import('../../components/MyTasks'),
  loading: () => <></>
});
const RecentActivity = Loadable({
  loader: () => import('../../components/RecentActivity'),
  loading: () => <></>
});
import './styles.scss';
import './responsive-styles.scss';

function Home({ routeKey, pageName }) {
  const [isOpened, setIsOpened] = useState(true);

  return (
    <div className="home__container __body__section">
      {/* --------it has two flex based columns--------- */}
      {/* black section */}
      <BlackSectionController>
        <WelcomeUserInfo routeKey={routeKey} />
      </BlackSectionController>
      {/* second section with having quick link and my tasks */}
      <div className="__home__body__div__">
        {isOpened && <QuickLinks />}
        <span className={`__straight__line__controller ${!isOpened && '__reset__left__styling'}`}>
          <i
            id="home-page-collaper"
            className={`dx-icon-${isOpened ? 'back' : 'chevronright'} __drawer__icon__controller__`}
            onClick={() => {
              setIsOpened(!isOpened);
            }}
          ></i>
          <Tooltip
            target={`#home-page-collaper`}
            showEvent="mouseenter"
            hideEvent="mouseleave"
            hideOnOutsideClick={false}
          >
            <div>{`${isOpened ? `Hide Navigation` : `Show Navigation`}`}</div>
          </Tooltip>
        </span>
        <div className="__content__div__" style={{ left: isOpened ? 'auto' : '20px' }}>
          <div className={`${!isOpened && '__gap__10__'}`}>
            <MyTasks routeKey={routeKey} />
          </div>
          <RecentActivity routeKey={routeKey} />
        </div>
      </div>
    </div>
  );
}

export default Home;
