import React, { useCallback, memo } from 'react';
import { TextBox } from 'devextreme-react/text-box';

const ColumnInput = memo(({ label, tooltip, onChange }) => {
  const onValueChange = useCallback(
    (v, isTooltipText = false) => {
      onChange(v, isTooltipText);
    },
    [onChange]
  );

  return (
    <>
      <TextBox
        className="display-inline-block __ff__header__textbox"
        width={200}
        valueChangeEvent="input"
        label="Column name"
        value={label}
        onValueChange={onValueChange}
      />
      {label && (
        <TextBox
          className="display-inline-block __ff__header__textbox"
          width={200}
          valueChangeEvent="input"
          label="Tooltip"
          value={tooltip}
          onValueChange={(e) => onValueChange(e, true)}
        />
      )}
    </>
  );
});

export default ColumnInput;
