import React from 'react';
import PropTypes from 'prop-types';

function Footer({ light }) {

  return (
    <div className='__sign__in__footer__'>
      Powered by
      <a href="#" target="_blank">
        <h4 style={{color: light ? 'white' : 'black'}}>
          &nbsp;Internal Digital Transformation &copy; {new Date().getFullYear()}
        </h4>
      </a>
    </div>
  );
}

Footer.defaultProps = {
  light: false
};

Footer.propTypes = {
  light: PropTypes.bool
};

export default Footer;
